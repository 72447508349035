import { TileTables, TileColumns } from "domain/TileInformation";
import {
    TileColumnsService,
    TileTablesService,
} from "application/port/tilePort";
import {
    fetchTileTables,
    fetchTileColumns,
} from "service/api/tileImformationApi";

export interface ColumnsResponse {
    colorColumns: TileColumns;
    shapeColumns: TileColumns;
}

export const useGetTileTables = (): TileTablesService => {
    return {
        getTileTables: async (endpoint: string): Promise<TileTables> => {
            const init: RequestInit = {
                credentials: "include",
            };

            const res = await fetchTileTables(endpoint, init);

            return res;
        },
    };
};

export const useGetTileColumns = (): TileColumnsService => {
    return {
        getTileColumns: async (
            endpoint: string,
            tableName: string
        ): Promise<ColumnsResponse> => {
            const init: RequestInit = {
                credentials: "include",
            };

            const res = await fetchTileColumns(endpoint, init, tableName);

            return {
                colorColumns: { [tableName]: res.colorColumns },
                shapeColumns: { [tableName]: res.shapeColumns },
            };
        },
    };
};
