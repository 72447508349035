export type AvoidInformation = {
    avoidId?: number;
    ticketId: number;
    linkId?: number;
    organizationId: string;
    mapVersion?: string;
    coordinate: AvoidCoordinate;
    vehicleType: VehicleType;
    date: AvoidDateTime;
    time: AvoidDateTime;
    geoJson?: AvoidGeoJsonFeature;
};

export class AvoidGeoJsonFeature {
    type = "Feature";
    geometry: AvoidGeometry;
    properties: AvoidProperties;

    constructor(geometry: AvoidGeometry, properties: AvoidProperties) {
        this.geometry = geometry;
        this.properties = properties;
    }
}

export interface AvoidProperties {
    objectid: number;
}

export interface AvoidGeometry {
    type: "MultiLineString";
    coordinates: number[][][];
}

export type AvoidCoordinate = {
    latitude: number;
    longitude: number;
};

export type VehicleType =
    | "CompactCar"
    | "MediumOrdinaryCar"
    | "HeavyOrdinaryCar"
    | "SmallTruck"
    | "MediumTruck"
    | "HeavyTruck"
    | null;

export type AvoidDateTime = {
    start: Date | null;
    end: Date | null;
};

export type AvoidMode = "view" | "add" | "edit" | "remove";

export type AvoidTicket = {
    status: AvoidMode;
    ticketId: number;
    organizationId: string;
    coordinate: AvoidCoordinate;
    vehicleType: string | null;
    date: AvoidDateTime;
    time: AvoidDateTime;
};
