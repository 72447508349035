export class PathInfo {
    startPoint: PointParam;
    stopPoint: PointParam;
    datetime: number;
    pathData: number[][];
    pathTime: number;
    routeColor: number[];

    constructor(
        startPoint: PointParam,
        stopPoint: PointParam,
        datetime: number,
        routeColor: number[]
    ) {
        this.startPoint = startPoint;
        this.stopPoint = stopPoint;
        this.datetime = datetime;
        this.pathData = [];
        this.pathTime = 0;
        this.routeColor = routeColor;
    }
}

export interface PointParam {
    latitude: number;
    longitude: number;
    preferredSide: string;
}
