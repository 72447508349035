import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useUpdateTileDefinitions } from "application/tile/tileDefinitionUsecase";
import { useTileDifinitionStorage } from "service/map/mapStorageAdapter";

import { tileLayerPanelStyle } from "./style";

interface Props {
    tableName: string;
    columnsByTable: {
        [key: string]: string[];
    };
    shapeColumnsByTable: {
        [key: string]: string[];
    };
}

const TileLayerSetBox = (props: Props) => {
    const classes = tileLayerPanelStyle();

    const { updateTileDefinitions } = useUpdateTileDefinitions();
    const { tileDefinitions } = useTileDifinitionStorage();
    const tileDefinition = tileDefinitions[props.tableName];

    return (
        <div>
            <div className={classes.selectbox}>
                <InputLabel>Select Column</InputLabel>
                <Select
                    value={tileDefinition?.colorColumnName ?? ""}
                    onChange={(val) =>
                        updateTileDefinitions(
                            String(val.target.value),
                            props.tableName,
                            "colorColumnName"
                        )
                    }
                >
                    <MenuItem disabled value={""}>
                        <em>None</em>
                    </MenuItem>
                    {props.columnsByTable[props.tableName].map(
                        (column_name, col_index) => (
                            <MenuItem key={col_index} value={column_name}>
                                {" "}
                                {column_name}{" "}
                            </MenuItem>
                        )
                    )}
                </Select>
            </div>

            <div className={classes.selectbox}>
                <InputLabel>Select Geometry</InputLabel>
                <Select
                    value={tileDefinition?.geometryName ?? ""}
                    onChange={(val) =>
                        updateTileDefinitions(
                            String(val.target.value),
                            props.tableName,
                            "geometryName"
                        )
                    }
                >
                    <MenuItem disabled value={""}>
                        <em>None</em>
                    </MenuItem>
                    {props.shapeColumnsByTable[props.tableName].map(
                        (column_name, col_index) => (
                            <MenuItem key={col_index} value={column_name}>
                                {" "}
                                {column_name}{" "}
                            </MenuItem>
                        )
                    )}
                </Select>
            </div>

            <div className={classes.selectbox}>
                <InputLabel>Set Condition</InputLabel>
                <Select
                    value={tileDefinition?.condColumnName ?? ""}
                    onChange={(val) =>
                        updateTileDefinitions(
                            String(val.target.value),
                            props.tableName,
                            "condColumnName"
                        )
                    }
                >
                    <MenuItem disabled value="">
                        <em>None</em>
                    </MenuItem>
                    {props.columnsByTable[props.tableName].map(
                        (column_name, col_index) => (
                            <MenuItem key={col_index} value={column_name}>
                                {" "}
                                {column_name}{" "}
                            </MenuItem>
                        )
                    )}
                </Select>

                <Select
                    className={classes.selectbox}
                    value={tileDefinition?.inequalitySign ?? ""}
                    onChange={(val) =>
                        updateTileDefinitions(
                            String(val.target.value),
                            props.tableName,
                            "inequalitySign"
                        )
                    }
                >
                    <MenuItem disabled value="">
                        --
                    </MenuItem>
                    {["<=", "<", ">", ">="].map((sign, col_index) => (
                        <MenuItem key={col_index} value={sign}>
                            {" "}
                            {sign}{" "}
                        </MenuItem>
                    ))}
                </Select>

                <TextField
                    style={{ width: "30px" }}
                    type="number"
                    onChange={(val) =>
                        updateTileDefinitions(
                            val.target.value,
                            props.tableName,
                            "condValue"
                        )
                    }
                    value={tileDefinition?.condValue ?? 0}
                />
                <br />

                <Button
                    className={classes.button}
                    onClick={() =>
                        updateTileDefinitions(
                            true,
                            props.tableName,
                            "isCondition"
                        )
                    }
                >
                    apply
                </Button>
                <Button
                    className={classes.button}
                    onClick={() =>
                        updateTileDefinitions(
                            false,
                            props.tableName,
                            "isCondition"
                        )
                    }
                >
                    deapply
                </Button>
            </div>
        </div>
    );
};

export default TileLayerSetBox;
