import React from "react";

import Button from "@material-ui/core/Button";

import { useFeedback } from "application/feedback/feedbackUsecase";

import { useFeedbackPanelStyle } from "component/FeedbackPanel/style";
import { useNotificationStorage } from "service/notification/notificationStorageAdapter";

interface Props {
    projectID: string;
    startDate: string;
    endDate: string;
    errorHandler: (code: number, message: string) => void;
}

const FeedbackButton = (props: Props) => {
    const classes = useFeedbackPanelStyle();

    const feedbackUsecase = useFeedback();
    const notificationStorageService = useNotificationStorage();

    const fetchFeedbackData = async (projectID: string) => {
        const result = await feedbackUsecase.updateFeedbackData(
            projectID,
            props.startDate,
            props.endDate
        );
        console.log(result);
        if (!result) {
            notificationStorageService.show(
                "Error fetching feedback data",
                "error"
            );
        }
    };

    return (
        <>
            <Button
                className={classes.button}
                onClick={() => {
                    fetchFeedbackData(props.projectID);
                }}
            >
                fetch data
            </Button>
        </>
    );
};

export default FeedbackButton;
