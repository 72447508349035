import React from "react";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddLocationIcon from "@material-ui/icons/AddLocation";

import { PointParam } from "domain/PathInfo";
import { SearchPinState } from "application/path/SearchPinState";
import { useSearchPinState } from "service/map/mapStorageAdapter";

interface Props {
    index: number;
    lat: number;
    lon: number;
    preferredSide: string;
    title: string;
    point: string;
    updatePathPoint: (param: PointParam, point: string, index: number) => void;
}

const RouteSearchTextField = (props: Props) => {
    const { updateSearchPinState } = useSearchPinState();

    const inputHandler = (event, index) => {
        const param = {
            latitude: props.lat,
            longitude: props.lon,
            preferredSide: props.preferredSide,
        };

        const value =
            event.target.type == "number"
                ? parseFloat(event.target.value)
                : event.target.value;

        param[event.target.name] = value;
        props.updatePathPoint(param, props.point, index);
    };

    return (
        <div>
            {props.title}
            <IconButton
                size="small"
                onClick={() => {
                    const p = props.point == "start" ? true : false;
                    const newSearchPoint = new SearchPinState(
                        props.index,
                        true,
                        p
                    );
                    updateSearchPinState(newSearchPoint);
                }}
            >
                <AddLocationIcon fontSize="small" />
            </IconButton>
            <div>
                <TextField
                    type="number"
                    name={"latitude"}
                    value={props.lat}
                    onChange={(event) => inputHandler(event, props.index)}
                />
                <TextField
                    type="number"
                    name={"longitude"}
                    value={props.lon}
                    onChange={(event) => inputHandler(event, props.index)}
                />
                <Select
                    name={"preferredSide"}
                    value={props.preferredSide}
                    onChange={(event) => inputHandler(event, props.index)}
                >
                    <MenuItem value="either">either</MenuItem>
                    <MenuItem value="left">left</MenuItem>
                </Select>
            </div>
        </div>
    );
};

export default RouteSearchTextField;
