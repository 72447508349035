import { fetchWrapper } from "./wrapper";

type RoadLookupResponse = { lat: number; lon: number };

export const fetchRoadLookup = async (
    endpoint: string,
    init: RequestInit,
    tablename: string,
    roadid: string
) => {
    return fetchWrapper<RoadLookupResponse>(
        `${endpoint}/roadlookup/${tablename}-${roadid}`,
        init
    )
        .then((resJson) => {
            return {
                success: true,
                latitude: resJson.lat,
                longitude: resJson.lon,
            };
        })
        .catch((err) => {
            // TODO: implement error handling
            console.log(err);
            return { success: false, latitude: 10000, longitude: 10000 };
        });
};
