import polyline from "@mapbox/polyline";

import { fetchWrapper } from "./wrapper";
import { SearchPathResponse } from "service/path/pathAdapter";

type PathFetchResponse = {
    shape: string;
    time: number;
};

export const fetchSearchPath = async (
    endpoint: string,
    init: RequestInit
): Promise<SearchPathResponse> => {
    return fetchWrapper<PathFetchResponse>(endpoint + "/route/search", init)
        .then((resJson) => {
            const d = resJson.shape;
            const pathTime = resJson.time;
            const pathData = (d ? polyline.decode(d) : []).map((row) =>
                row.reverse()
            );

            return { pathData, pathTime };
        })
        .catch((err) => {
            console.log(err);
            return { pathData: [], pathTime: 0 };
        });
};
