import colormap from "colormap";

export const getRouteColors = (): number[][] => {
    const colors = colormap({
        colormap: "jet",
        nshades: 10,
        format: "rba",
        alpha: 1,
    });

    const routeColors = colors.map((color) => color.slice(0, 3));

    return routeColors;
};

export const getRandomColorMaps = (labelSet: Set<string>) => {
    const n_colors = labelSet.size > 6 ? labelSet.size : 6;
    const colors = colormap({
        colormap: "jet",
        nshades: n_colors,
        format: "rba",
        alpha: 1,
    });

    const labelArr = Array.from(labelSet);

    const colorMap: Map<string, number[]> = new Map();
    colors.slice(0, labelArr.length).forEach((color, index) => {
        colorMap.set(labelArr[index], [...color.slice(0, 3), 255]);
    });

    return colorMap;
};

// export const numberToColor = (colorList, largestNumber, number) => {
//     let siz = colorList.length;
//     let bins = generateBins(largestNumber, siz);
//     for (let i = 0; i < siz - 1; i++) {
//         if (number > bins[i]) {
//             return colorList[i];
//         }
//     }
//     return colorList[siz - 1];
// };

const componentToHex = (c) => {
    if (c === undefined) {
        return;
    }
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
