import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { headerStyle } from "./style";
import { useMapStyleStorage } from "service/map/mapStorageAdapter";

export const MapStyleSelect = () => {
    const classes = headerStyle();
    const mapStyleStorage = useMapStyleStorage();

    const handleChangeMapStyle = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        mapStyleStorage.setMapStyle(event.target.value as string);
    };

    return (
        <FormControl className={classes.select}>
            <InputLabel id="map-style-select">Map-style</InputLabel>
            <Select
                labelId="map-style-select"
                value={mapStyleStorage.mapStyle}
                onChange={handleChangeMapStyle}
            >
                <MenuItem value={"streets-v11"}>streets</MenuItem>
                <MenuItem value={"outdoors-v11"}>outdoors</MenuItem>
                <MenuItem value={"light-v10"}>light</MenuItem>
                <MenuItem value={"dark-v10"}>dark</MenuItem>
                <MenuItem value={"satellite-v9"}>satellite</MenuItem>
                <MenuItem value={"satellite-streets-v11"}>
                    satellite-streets
                </MenuItem>
                <MenuItem value={"navigation-day-v1"}>navigation-day</MenuItem>
                <MenuItem value={"navigation-night-v1"}>
                    navigation-night
                </MenuItem>
            </Select>
        </FormControl>
    );
};
