import React from "react";

import { GeoCoordinate, HoveredLayer } from "domain/LayerInformation";

interface Props {
    hoveredLayer: HoveredLayer | null;
    coords: GeoCoordinate | null;
}

const GeoToolTip = (props: Props) => {
    const { sourceLayer, tile } = props.hoveredLayer || {};

    const style = {
        background: "white",
        position: "relative",
        display: "inline-grid",
        paddingLeft: "1em",
    };

    const isHovering = Boolean(sourceLayer && tile);

    if (isHovering) {
        const coords = props.coords;
        return (
            <div style={style as any}>
                tile: x: {tile.x}, y: {tile.y}, z: {tile.z}
                {coords && (
                    <div>
                        lng: {coords.longitude}, lat: {coords.latitude}
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
};

export default GeoToolTip;
