import { Geometry } from "./Geometry";

export class TileGeoJsonFeature {
    type = "Feature";
    geometry: Geometry;
    properties: TileProperties;

    constructor(geometry: Geometry, properties: TileProperties) {
        this.geometry = geometry;
        this.properties = properties;
    }
}

export interface TileProperties {
    objectid: number;
    col_value: number;
    roadclass_c: number;
}

export interface TileStat {
    [key: number]: number;
}
