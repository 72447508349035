import React from "react";

import Router from "./pages/Router";
import { AuthProvider } from "service/store/authStore";

const App = () => {
    return (
        <AuthProvider>
            <Router></Router>
        </AuthProvider>
    );
};

export default App;
