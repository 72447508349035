import { AvoidMode } from "domain/AvoidInformation";
import { useAvoidModeStorage } from "service/avoid/avoidStorageAdapter";

export const useAvoidMode = () => {
    const avoidMode = useAvoidModeStorage();

    const changeAvoidMode = (mode: AvoidMode): void => {
        avoidMode.setMode(mode);
    };

    return { changeAvoidMode };
};
