import { parse } from "wellknown";

import { FeedbackData, RawFeedbackData } from "domain/FeedbackData";
import { useRawFeedbackGetter } from "service/feedback/rawFeedbackAdapter";
import { useFeedbackDataStorage } from "service/map/mapStorageAdapter";

import { getRandomColorMaps } from "utils/colorUtils";

const rowsToGeoJSON = (
    rows: RawFeedbackData[],
    geoColumn: string
): FeedbackData[] => {
    if (!rows || !geoColumn) return [];

    // Convert rows to GeoJSON features.
    const features = rows
        .map((row) => {
            if (!row[geoColumn]) return null;
            try {
                const geometry = parse(row[geoColumn]);
                const properties = {
                    event_name: row.event_name,
                    event_timestamp: row.event_timestamp,
                    fb_type: row.fb_type,
                };
                return new FeedbackData(geometry, properties);
            } catch (e) {
                return null;
            }
        })
        .filter((v): v is NonNullable<typeof v> => v != null);

    return features;
};

export const useFeedback = () => {
    const rawFeedbackGetter = useRawFeedbackGetter();
    const feedbackDataStorage = useFeedbackDataStorage();

    const updateFeedbackData = async (
        projectID: string,
        startDate: string,
        endDate: string
    ): Promise<boolean> => {
        try {
            const rows = await rawFeedbackGetter.getRawFeedback(
                projectID,
                startDate,
                endDate
            );
            const geoColumn = "WKT";
            const features = rowsToGeoJSON(rows, geoColumn);
            if (features.length == 0) {
                return false;
            }
            const colorMap = getColorMap(features);

            feedbackDataStorage.updateFeedbackData(features);
            feedbackDataStorage.updateColorMap(colorMap);
            return true;
        } catch (e) {
            return false;
        }
    };

    const getColorMap = (features: FeedbackData[]): Map<string, number[]> => {
        const fbTypeSet: Set<string> = new Set();
        features.forEach((row) => {
            fbTypeSet.add(row["properties"]["fb_type"]);
        });

        const colorMap: Map<string, number[]> = getRandomColorMaps(fbTypeSet);

        return colorMap;
    };

    const deleteFeedbackData = (): void => {
        feedbackDataStorage.updateFeedbackData([]);
        feedbackDataStorage.updateColorMap(new Map());
    };

    return { updateFeedbackData, deleteFeedbackData };
};
