import { NotificationStorageService } from "application/port/notificationPort";
import { useNotificationStore } from "service/store/notificationStore";
import { Color } from "@material-ui/lab";

export const useNotificationStorage = (): NotificationStorageService => {
    const {
        open,
        setOpen,
        message,
        setMessage,
        severity,
        setSeverity,
    } = useNotificationStore();

    const show = (message: string, severity: Color) => {
        setOpen(true);
        setSeverity(severity);
        setMessage(message);
    };

    const close = () => {
        setOpen(false);
        setMessage("");
    };

    return {
        open,
        message,
        severity,
        show,
        close,
    };
};
