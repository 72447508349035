export class SearchPinState {
    index: number;
    using: boolean;
    isStart: boolean;

    constructor(index: number, using: boolean, isStart: boolean) {
        this.index = index;
        this.using = using;
        this.isStart = isStart;
    }
}
