export const fetchMvt = async (
    endpoint: string,
    init: RequestInit
): Promise<ArrayBuffer> => {
    return fetch(endpoint, init)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
            return buffer;
        });
};
