import React, { createContext, useContext, useState } from "react";
import { appConfig } from "../../config";

const BACKEND_ENDPOINT = appConfig.BACKEND_ENDPOINT;

export interface AuthContextProps {
    idToken: string;
    setIdToken: React.Dispatch<React.SetStateAction<string>>;
    organization: string;
    setOrganization: React.Dispatch<React.SetStateAction<string>>;
    readableOrgIdList: string[];
    setReadableOrgIdList: React.Dispatch<React.SetStateAction<string[]>>;
    backendUrl: string;
    setBackendUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextProps>(
    {} as AuthContextProps
);
export const useAuthStore = (): AuthContextProps => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
    const [idToken, setIdToken] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [readableOrgIdList, setReadableOrgIdList] = useState<string[]>([]);
    const [backendUrl, setBackendUrl] = useState<string>(
        BACKEND_ENDPOINT || ""
    );

    const value: AuthContextProps = {
        idToken,
        setIdToken,
        organization,
        setOrganization,
        readableOrgIdList,
        setReadableOrgIdList,
        backendUrl,
        setBackendUrl,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
