import {
    AvoidModeStorageService,
    AvoidOrganizationIdListStorageService,
    AvoidOrganizationIdStorageService,
    AvoidTicketIdStorageService,
    SelectedAvoidListStorageService,
    AvoidListStorageService,
    TicketAvoidStorageService,
    AvoidModalPositionStorageService,
} from "application/port/avoidPort";
import { useAvoidStore } from "service/store/avoidStore";

export const useAvoidListStorage = (): AvoidListStorageService => {
    const { avoidList, setAvoidList } = useAvoidStore();
    return { avoidList, setAvoidList };
};

export const useSelectedAvoidListStorage = (): SelectedAvoidListStorageService => {
    const { selectedAvoidList, setSelectedAvoidList } = useAvoidStore();
    return { selectedAvoidList, setSelectedAvoidList };
};

export const useAvoidModeStorage = (): AvoidModeStorageService => {
    const { mode, setMode } = useAvoidStore();
    return { mode, setMode };
};

export const useAvoidTicketIdStorage = (): AvoidTicketIdStorageService => {
    const { ticketId, setTicketId } = useAvoidStore();
    return { ticketId, setTicketId };
};

export const useAvoidOrganizationIdStorage = (): AvoidOrganizationIdStorageService => {
    const { organizationId, setOrganizationId } = useAvoidStore();
    return { organizationId, setOrganizationId };
};

export const useAvoidOrganizationIdListStorage = (): AvoidOrganizationIdListStorageService => {
    const { organizationIdList, setOrganizationIdList } = useAvoidStore();
    return { organizationIdList, setOrganizationIdList };
};

export const useTicketAvoidStorage = (): TicketAvoidStorageService => {
    const { ticketAvoid, setTicketAvoid } = useAvoidStore();
    return { ticketAvoid, setTicketAvoid };
};

export const useAvoidModalStorage = (): AvoidModalPositionStorageService => {
    const { modalPosition, setModalPosition } = useAvoidStore();
    const setModalPositionXY = (x: number, y: number) => {
        setModalPosition({ x: x, y: y });
    };
    return { modalPosition, setModalPositionXY };
};
