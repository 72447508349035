import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

export const tileLayerPanelStyle = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            position: "absolute",
            top: 150,
            width: 200,
            maxHeight: "70%",
            backgroundColor: color.backgroud.panel,
            padding: 12,
            display: "block",
            overflow: "scroll",
        },
        box: {
            borderRadius: "0.6em",
            backgroundColor: color.backgroud.box,
            marginTop: 3,
            padding: 3,
        },
        selectbox: {
            marginTop: 15,
        },
        typography: {
            color: color.font.typography,
        },
        button: {
            cursor: "pointer",
            marginRight: 3,
            backgroundColor: color.backgroud.button,
        },
        modal: {
            position: "absolute",
            top: "35%",
            left: "50%",
            display: "block",
            padding: "2em",
            minWidth: "20em",
            maxWidth: "70%",
            backgroundColor: "#fff",
            borderRadius: "1em",
            transform: "translate(-50%, -50%)",
            outline: "transparent",
            textAlign: "center",
        },
        modalButton: {
            textTransform: "none",
            width: "70%",
        },
        modalCloseButton: {
            textTransform: "none",
            width: "70%",
            marginTop: 30,
        },
    })
);
