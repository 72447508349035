import React from "react";

import { XYPlot, VerticalBarSeries } from "react-vis/es";

import * as colorUtils from "../../utils/colorUtils";
import { histgramStyle } from "./style";

import { map, range } from "lodash-es";

type Rgb = [number, number, number];
interface Props {
    colors: any;
    numBins: number;
    data: any;
}

const Histogram = (props: Props) => {
    const classes = histgramStyle();

    const hexColors = map(props.colors, (rgb: Rgb) =>
        colorUtils.rgbToHex(rgb[0], rgb[1], rgb[2])
    );

    return (
        <div className={classes.root}>
            <XYPlot
                width={300}
                height={400}
                colorRange={hexColors}
                colorDomain={range(0, props.numBins, 1)}
                colorScale="category"
            >
                <VerticalBarSeries
                    data={props.data}
                    style={{}}
                    stack={false}
                ></VerticalBarSeries>
            </XYPlot>
        </div>
    );
};

export default Histogram;
