import React, { useState } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { avoidModalStyle } from "./style";

import { AvoidMode, VehicleType } from "domain/AvoidInformation";
import {
    useAvoidModeStorage,
    useSelectedAvoidListStorage,
} from "service/avoid/avoidStorageAdapter";
import { useAvoidService } from "application/avoid/avoidUsecase";

interface Props {
    positionX: number;
    positionY: number;
}

export const AvoidModal = (props: Props) => {
    const { positionX, positionY } = props;
    const { mode } = useAvoidModeStorage();
    const [selectedAvoidIndex, setSelectedAvoidIndex] = useState<number>(0);
    const [loading, setLoading] = React.useState(false);

    const classes = avoidModalStyle({ x: positionX, y: positionY });
    const {
        selectedAvoidList,
        setSelectedAvoidList,
    } = useSelectedAvoidListStorage();

    const avoidUsecase = useAvoidService();

    // TODO: avoidの各プロパティをuseStateで持っておくか検討

    const handleChangeLatitude = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                coordinate: {
                    ...selectedAvoidList[0].coordinate,
                    latitude: Number(event.target.value),
                },
            },
        ]);
    };

    const handleChangeLongitude = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                coordinate: {
                    ...selectedAvoidList[0].coordinate,
                    longitude: Number(event.target.value),
                },
            },
        ]);
    };

    const handleChangeVehicleType = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                vehicleType: event.target.value as VehicleType,
            },
        ]);
    };

    const handleStartDateChange = (date: Date | null) => {
        if (!date) {
            return;
        }
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                date: {
                    ...selectedAvoidList[0].date,
                    start: date,
                },
            },
        ]);
    };

    const handleEndDateChange = (date: Date | null) => {
        if (!date) {
            return;
        }
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                date: {
                    ...selectedAvoidList[0].date,
                    end: date,
                },
            },
        ]);
    };

    const handleStartTimeChange = (date: Date | null) => {
        if (!date) {
            return;
        }
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                time: {
                    ...selectedAvoidList[0].time,
                    start: date,
                },
            },
        ]);
    };

    const handleEndTimeChange = (date: Date | null) => {
        if (!date) {
            return;
        }
        setSelectedAvoidList([
            {
                ...selectedAvoidList[0],
                time: {
                    ...selectedAvoidList[0].time,
                    end: date,
                },
            },
        ]);
    };

    const addAvoid = () => {
        if (confirm("処理を確定しますか？")) {
            setLoading(true);
            avoidUsecase.addAvoid(selectedAvoidList[0]).then(() => {
                setLoading(false);
            });
        }
    };

    const editAvoid = () => {
        if (confirm("処理を確定しますか？")) {
            setLoading(true);
            avoidUsecase.updateAvoid(selectedAvoidList[0]).then(() => {
                setLoading(false);
            });
        }
    };

    const removeAvoid = () => {
        const avoidId = selectedAvoidList[0].avoidId;
        if (!avoidId) {
            return;
        }
        if (confirm("処理を確定しますか？")) {
            setLoading(true);
            avoidUsecase.removeAvoid(avoidId).then(() => {
                setLoading(false);
            });
        }
    };

    const closeModal = () => {
        setSelectedAvoidList([]);
    };

    const renderStreetViewButton = () => {
        return (
            <Button
                className={classes.button}
                variant="outlined"
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=
                ${selectedAvoidList[selectedAvoidIndex].coordinate.latitude},
                ${selectedAvoidList[selectedAvoidIndex].coordinate.longitude}
            `}
            >
                Street View
            </Button>
        );
    };

    const renderButtons = (mode: AvoidMode) => {
        switch (mode) {
            case "view":
                return (
                    <>
                        <Button
                            className={classes.button}
                            disabled={selectedAvoidIndex == 0}
                            onClick={() =>
                                setSelectedAvoidIndex(selectedAvoidIndex - 1)
                            }
                        >
                            Prev
                        </Button>
                        {renderStreetViewButton()}
                        <Button
                            className={classes.button}
                            disabled={
                                selectedAvoidIndex ==
                                selectedAvoidList.length - 1
                            }
                            onClick={() =>
                                setSelectedAvoidIndex(selectedAvoidIndex + 1)
                            }
                        >
                            Next
                        </Button>
                    </>
                );
            case "add":
                return (
                    <>
                        {renderStreetViewButton()}
                        <Button
                            className={classes.blueButton}
                            onClick={addAvoid}
                        >
                            Add
                        </Button>
                    </>
                );
            case "edit":
                return (
                    <>
                        {renderStreetViewButton()}
                        <Button
                            className={classes.blueButton}
                            onClick={editAvoid}
                        >
                            Edit
                        </Button>
                    </>
                );
            case "remove":
                return (
                    <>
                        {renderStreetViewButton()}
                        <Button
                            className={classes.redButton}
                            onClick={removeAvoid}
                        >
                            Remove
                        </Button>
                    </>
                );
            default:
                return null;
        }
    };

    if (selectedAvoidList.length == 0 || !selectedAvoidList[0].linkId) {
        return null;
    }
    return (
        <div className={classes.panel}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <IconButton className={classes.closeIcon} onClick={closeModal}>
                <CloseIcon />
            </IconButton>

            <div className={classes.header}>
                <Typography className={classes.typography} variant="h5">
                    {mode}
                </Typography>
                <Typography className={classes.typography} variant="h6">
                    ticketId: {selectedAvoidList[selectedAvoidIndex].ticketId}
                </Typography>
            </div>

            <div className={classes.coordInput}>
                <div>
                    <InputLabel>lat</InputLabel>
                    <Input
                        value={
                            selectedAvoidList[selectedAvoidIndex].coordinate
                                .latitude
                        }
                        disabled
                        className={classes.input}
                        onChange={handleChangeLatitude}
                    />
                </div>
                <div>
                    <InputLabel>lon</InputLabel>
                    <Input
                        value={
                            selectedAvoidList[selectedAvoidIndex].coordinate
                                .longitude
                        }
                        disabled
                        className={classes.input}
                        onChange={handleChangeLongitude}
                    />
                </div>
            </div>

            <InputLabel>Vehicle Type</InputLabel>
            <Select
                value={selectedAvoidList[selectedAvoidIndex].vehicleType}
                disabled
                className={classes.selectbox}
                onChange={handleChangeVehicleType}
            >
                <MenuItem value="CompactCar">CompactCar</MenuItem>
                <MenuItem value="MediumOrdinaryCar">MediumOrdinaryCar</MenuItem>
                <MenuItem value="HeavyOrdinaryCar">HeavyOrdinaryCar</MenuItem>
                <MenuItem value="SmallTruck">SmallTruck</MenuItem>
                <MenuItem value="MediumTruck">MediumTruck</MenuItem>
                <MenuItem value="HeavyTruck">HeavyTruck</MenuItem>
            </Select>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <InputLabel>Date</InputLabel>

                    <KeyboardDatePicker
                        disabled
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        value={selectedAvoidList[selectedAvoidIndex].date.start}
                        onChange={handleStartDateChange}
                        className={classes.dateTimePicker}
                    />
                    <span className={classes.dateTimeDash}>〜</span>
                    <KeyboardDatePicker
                        disabled
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        value={selectedAvoidList[selectedAvoidIndex].date.end}
                        onChange={handleEndDateChange}
                        className={classes.dateTimePicker}
                    />
                </div>

                <div>
                    <InputLabel>Time</InputLabel>

                    <KeyboardTimePicker
                        disabled
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        value={selectedAvoidList[selectedAvoidIndex].time.start}
                        onChange={handleStartTimeChange}
                        className={classes.dateTimePicker}
                    />
                    <span className={classes.dateTimeDash}>〜</span>
                    <KeyboardTimePicker
                        disabled
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        value={selectedAvoidList[selectedAvoidIndex].time.end}
                        onChange={handleEndTimeChange}
                        className={classes.dateTimePicker}
                    />
                </div>
            </MuiPickersUtilsProvider>

            {renderButtons(mode)}
        </div>
    );
};
