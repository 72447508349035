import { appConfig } from "config";
import { Result, Success, Failure } from "domain/Result";
import { fetchWrapper } from "./wrapper";

type CustomFields = {
    cf_reason: string;
    cf_vehicle_type: string;
    cf_lon: number;
    cf_lat: number;
    cf_start_date: string;
    cf_end_date: string;
    cf_start_time: string;
    cf_end_time: string;
    cf_organization_id: string;
};

type ResponseTicket = {
    status: number;
    custom_fields: CustomFields;
};

type RequestPutTicket = {
    status: number;
};

export const getTicket = async (
    ticketId: number
): Promise<Result<ResponseTicket, Error>> => {
    const endpoint = `${appConfig.FRESHDESK_URL}/tickets/${ticketId}`;
    const headers = {
        Authorization: `Basic ${appConfig.FRESHDESK_APIKEY}`,
        "Content-Type": "application/json",
    };
    const init: RequestInit = {
        method: "GET",
        headers: headers,
    };

    return fetchWrapper<ResponseTicket>(endpoint, init)
        .then((resJson) => {
            const { status, custom_fields } = resJson;

            return new Success({ status, custom_fields });
        })
        .catch((err) => {
            // TODO: error handling
            console.log(err);

            return new Failure(err);
        });
};

export const putTicket = async (
    ticketId: number,
    body: RequestPutTicket
): Promise<Result<number, Error>> => {
    const endpoint = `${appConfig.FRESHDESK_URL}/tickets/${ticketId}`;
    const headers = {
        Authorization: `Basic ${appConfig.FRESHDESK_APIKEY}`,
        "Content-Type": "application/json",
    };
    const init: RequestInit = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(body),
    };

    return fetchWrapper<ResponseTicket>(endpoint, init)
        .then((resJson) => {
            const { status } = resJson;

            return new Success(status);
        })
        .catch((err) => {
            console.log(err);

            return new Failure(err);
        });
};
