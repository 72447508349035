import { PathInfo } from "domain/PathInfo";
import { usePathSearcher, SearchPathResponse } from "service/path/pathAdapter";

export const useSearchPath = () => {
    const path = usePathSearcher();

    const searchPath = async (
        endpoint: string,
        pathInfo: PathInfo
    ): Promise<SearchPathResponse> => {
        const params = {
            s_latitude: pathInfo.startPoint.latitude,
            s_longitude: pathInfo.startPoint.longitude,
            s_preferredSide: pathInfo.startPoint.preferredSide,
            g_latitude: pathInfo.stopPoint.latitude,
            g_longitude: pathInfo.stopPoint.longitude,
            g_preferredSide: pathInfo.stopPoint.preferredSide,
            datetime: pathInfo.datetime,
        };
        const res = await path.searchPath(endpoint, params);

        return res;
    };

    return {
        searchPath,
    };
};
