import React, { useState, useContext } from "react";

import "mapbox-gl/dist/mapbox-gl.css";
import { StaticMap } from "react-map-gl";
import DeckGL from "@deck.gl/react";
import { TileLayer } from "@deck.gl/geo-layers";
import { PathLayer, GeoJsonLayer } from "@deck.gl/layers";
import { memoize, map, pick, values } from "lodash-es";
import colormap from "colormap";

import PinIcon from "img/pin.png";
import { appConfig } from "config";
import * as binUtils from "utils/binUtils";

import { MapViewState } from "domain/MapViewState";
import {
    DeckglLayerInformation,
    DeckglTileLayerInformation,
} from "domain/LayerInformation";
import { TileGeoJsonFeature } from "domain/TileGeoJsonFeature";
import { TileDefinition } from "domain/TileDefinition";
import { PathInfo } from "domain/PathInfo";
import { AvoidGeoJsonFeature } from "domain/AvoidInformation";

import { SearchPinState } from "application/path/SearchPinState";
import { TileGeoJsonFeatureUsecase } from "application/tile/tileGeoJsonFeatureUsecase";
import { useAvoidMarker } from "application/layer/avoidMarkerUsecase";

import { MapContext } from "service/store/mapStore";
import { TileLayerPanelProvider } from "service/store/tileLayerPanelStore";
import {
    useHoveredLayerInformation,
    useMapStyleStorage,
} from "service/map/mapStorageAdapter";

import Histogram from "component/Histogram/Histogram";
import TileLayerPanel from "component/TileLayerPanel/TileLayerPanel";
import RouteSearchPanel from "component/RouteSearchPanel/RouteSearchPanel";
import OptionsPanel from "component/OptionPanel/OptionPanel";
import FeedbackPanel from "component/FeedbackPanel/FeedbackPanel";
import LayerTooltip from "component/Tooltip/LayerToolTip";
import GeoToolTip from "component/Tooltip/GeoTooltip";
import { AvoidModal } from "component/AvoidModal/AvoidModal";
import { AvoidPanel } from "component/AvoidPanel/AvoidPanel";
import { useAvoidService } from "application/avoid/avoidUsecase";
import {
    useAvoidListStorage,
    useAvoidModalStorage,
    useSelectedAvoidListStorage,
} from "service/avoid/avoidStorageAdapter";

const isDebug = false;

const NUM_BINS = 50;

const bins: any[] = binUtils.generateBins(0, 70, NUM_BINS);

const colors: any = colormap({
    colormap: "RdBu",
    nshades: NUM_BINS,
    format: "rba",
    alpha: 1,
}).map((ls) => ls.slice(0, 3));

const MAPBOX_ACCESS_TOKEN = appConfig.MAPBOX_ACCESS_TOKEN;

function log(...a) {
    if (isDebug) {
        console.log(a);
    }
}

const memoizedNumberToBin = memoize(binUtils.numberToBin, (bins, number) => {
    return number.toFixed(1);
});

// function toHistogram(bins, x, y, z) {}

interface Props {
    endpoint: string;
    gcpProjectID: string;
}

export const MapComponent = (props: Props) => {
    const [tileBinCounts, setTileBinCounts] = useState({});
    const [currentTileBinCount, setCurrentTileBinCount] = useState({});
    const [showHistogram, setShowHistogram] = useState<boolean>(false);

    const context = useContext(MapContext);

    const {
        hoveredObject,
        setHoveredObject,
        hoveredLayer,
        setHoveredLayer,
        hoveredTileCoordinate,
        setHoveredTileCoordinate,
        hoveredGeoCoordinate,
        setHoveredGeoCoordinate,
    } = useHoveredLayerInformation();
    const avoidMarker = useAvoidMarker();
    const avoidUsecase = useAvoidService();
    const avoidListStorage = useAvoidListStorage();
    const selectedAvoidListStorage = useSelectedAvoidListStorage();
    const { x, y } = useAvoidModalStorage().modalPosition;
    const mapStyleStorage = useMapStyleStorage();

    // TODO: 型定義
    const onClickMap = (info: any, event: any) => {
        const {
            searchPinState: { index, using, isStart },
        } = context;

        if (using) {
            const pathInfos = context.pathInfos;
            const pinState = new SearchPinState(index, !using, !isStart);

            // update coordinate
            const point = isStart ? "start" : "goal";
            if (point === "start") {
                pathInfos[index].startPoint["longitude"] = info.coordinate[0];
                pathInfos[index].startPoint["latitude"] = info.coordinate[1];
            } else if (point === "goal") {
                pathInfos[index].stopPoint["longitude"] = info.coordinate[0];
                pathInfos[index].stopPoint["latitude"] = info.coordinate[1];
            } else {
                // TODO: implement exception handling
                console.log("error");
            }
            context.setPathInfos(pathInfos);
            context.updateSearchPinState(pinState);
        }
    };

    // TODO: 型定義
    const onHoverMap = (info: any, event: any) => {
        const using = context.searchPinState.using;
        if (using) {
            const { x, y } = info;
            setHoveredTileCoordinate({ x, y });
        }
    };

    const onHoverTileLayer = (info: DeckglTileLayerInformation) => {
        onHoverLayer(info);

        const tileCoords = map(info.layer.state.tileset._tiles, (tile) => {
            const { x, y, z } = tile;
            return [x, y, z].toString();
        });

        const currentTileBinCounts = pick(tileBinCounts, tileCoords);
        setCurrentTileBinCount(
            binUtils.sumBinCounts(values(currentTileBinCounts))
        );
        setHoveredLayer({ sourceLayer: info.sourceLayer, tile: info.tile });
    };

    const onHoverLayer = (info: DeckglLayerInformation) => {
        const { x, y, sourceLayer, object, coordinate } = info;

        const geoCoordinate = coordinate || hoveredGeoCoordinate;

        setHoveredObject(object || hoveredObject);
        setHoveredGeoCoordinate({
            latitude: geoCoordinate[1],
            longitude: geoCoordinate[0],
        });
        setHoveredTileCoordinate({ x, y });
        setHoveredLayer({ sourceLayer });
    };

    const onViewStateChange = ({ viewState }: { viewState: MapViewState }) => {
        context.updateViewState(viewState);
    };

    const renderHistogram = () => {
        const obj = currentTileBinCount;
        if (!(Object.entries(obj).length === 0 && obj.constructor === Object)) {
            const data = map(binUtils.binCountsToXYData(obj), ({ x, y }) => {
                return { color: x, x: x, y: y || 0 };
            });
            // console.warn(data)
            return <Histogram colors={colors} numBins={NUM_BINS} data={data} />;
        }
    };

    const renderLayer = (selectTable: string, renderConfig: TileDefinition) => {
        if (!renderConfig.colorColumnName || !renderConfig.geometryName) {
            return;
        }
        const select_column = renderConfig.colorColumnName;
        const select_shapeColumn = renderConfig.geometryName;

        const isCondition = renderConfig.isCondition;
        const condColumn =
            renderConfig.condColumnName != undefined
                ? renderConfig.condColumnName
                : "";
        const inequalitySign =
            renderConfig.inequalitySign != undefined
                ? renderConfig.inequalitySign
                : "";
        const condValue = renderConfig.condValue;

        const params = {
            table: selectTable,
            column: select_column,
            shapeColumn: select_shapeColumn,
            isCondition: String(isCondition),
            condColumn: condColumn,
            inequalitySign: inequalitySign,
            condValue: String(condValue),
        };

        let id = selectTable + select_column + select_shapeColumn; // layerのID
        if (
            isCondition &&
            condColumn != undefined &&
            inequalitySign != undefined
        ) {
            id += condColumn + inequalitySign + condValue;
        }

        const tileUsecase = TileGeoJsonFeatureUsecase();
        const layer = new TileLayer({
            id: id,

            pickable: true,
            onHover: onHoverTileLayer,
            autoHighlight: true,

            getLineColor: (feature: TileGeoJsonFeature) =>
                tileUsecase.getLineColor(feature, colors, bins),
            getFillColor: [175, 206, 255],

            getLineWidth: (f: any) => {
                if (f.properties.layer === "transportation") {
                    switch (f.properties.class) {
                        case "primary":
                            return 12;
                        case "motorway":
                            return 16;
                        default:
                            return 6;
                    }
                }
                return 3;
            },
            lineWidthMinPixels: 1,

            getTileData: async ({ x, y, z }) => {
                const features = await tileUsecase.getTileData(
                    props.endpoint,
                    params,
                    x,
                    y,
                    z
                );
                const stat = tileUsecase.getTileStat(bins, NUM_BINS, features);
                tileBinCounts[[x, y, z].toString()] = stat;
                setTileBinCounts(tileBinCounts);

                return features;
            },

            onClick: (info: any) => {
                const { x, y, object } = info;
                const geojson = new TileGeoJsonFeature(
                    object.geometry,
                    object.properties
                );
                onClickRoad(geojson, x, y);
            },
        });

        return layer;
    };

    const onClickRoad = (
        geojson: TileGeoJsonFeature | AvoidGeoJsonFeature,
        x: number,
        y: number
    ) => {
        avoidUsecase.selectAvoid(geojson, x, y);
    };

    const renderPathLayer = (pathInfo: PathInfo) => {
        const data = [pathInfo.pathData];
        const layer = new PathLayer({
            id: "path-layer",
            data,
            pickable: true,
            getPath: (d: any) => d,
            getColor: (d) => pathInfo.routeColor,
            getWidth: 8,
        });

        return layer;
    };

    const renderFeedbackLayer = () => {
        const geojsonObj = {
            type: "FeatureCollection",
            features: context.feedbackData,
        };
        const feedbackColorMap = context.colorMap;
        const fblayer = new GeoJsonLayer({
            id: "geojson-layer",
            data: geojsonObj,
            pickable: true,
            onHover: onHoverLayer,
            stroked: false,
            filled: true,
            extruded: true,
            getFillColor: (d) => {
                const fb_type = d["properties"]["fb_type"];
                return feedbackColorMap.get(fb_type);
            },
            pointRadiusUnits: "pixels",
            getRadius: 10,
            getElevation: 30,
        });

        return fblayer;
    };

    const renderAvoidLayer = () => {
        const data = avoidListStorage.avoidList.map((avoid) => avoid.geoJson);
        const avoidLayer = new GeoJsonLayer({
            id: "avoid-layer",
            data: data,
            autoHighlight: true,
            pickable: true,
            getLineColor: [255, 0, 0],
            getLineWidth: 6,
            onClick: (info: any) => {
                const { x, y, object } = info;
                const geojson = new AvoidGeoJsonFeature(
                    object.geometry,
                    object.properties
                );
                onClickRoad(geojson, x, y);
            },
            lineWidthMinPixels: 1,
        });
        return avoidLayer;
    };

    const renderSelectedAvoidLayer = () => {
        const data = selectedAvoidListStorage.selectedAvoidList.map(
            (avoid) => avoid.geoJson
        );
        const selectedAvoidLayer = new GeoJsonLayer({
            id: "selected-avoid-layer",
            data: data,
            autoHighlight: true,
            pickable: true,
            getLineColor: [0, 0, 255],
            getLineWidth: 6,
            lineWidthMinPixels: 1,
        });
        return selectedAvoidLayer;
    };

    const renderLayers = () => {
        const tileLayers = Object.keys(
            context.tileDefinitions
        ).map((tableName) =>
            renderLayer(tableName, context.tileDefinitions[tableName])
        );

        const pathLayers = context.pathInfos.map((pathInfo) =>
            renderPathLayer(pathInfo)
        );

        const feedbackLayer = renderFeedbackLayer();

        const iconLayer = avoidMarker.renderAvoidMarker();

        const avoidLayer = renderAvoidLayer();

        const selectedAvoidLayer = renderSelectedAvoidLayer();

        return [
            ...tileLayers,
            ...pathLayers,
            feedbackLayer,
            avoidLayer,
            selectedAvoidLayer,
            iconLayer,
        ];
    };

    return (
        <div>
            <DeckGL
                onViewStateChange={onViewStateChange}
                viewState={context.viewState}
                layers={renderLayers()}
                controller={true}
                onClick={onClickMap}
                onHover={onHoverMap}
                getCursor={({ isDragging }: { isDragging: boolean }) => {
                    if (context.searchPinState.using) {
                        return `url(${PinIcon}) 16 32, auto`;
                    } else {
                        return isDragging ? "grabbing" : "grab";
                    }
                }}
            >
                <StaticMap
                    width={"100%"}
                    height={"100%"}
                    mapStyle={`mapbox://styles/mapbox/${mapStyleStorage.mapStyle}`}
                    mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                />
            </DeckGL>

            <RouteSearchPanel endpoint={props.endpoint} />

            <GeoToolTip
                hoveredLayer={hoveredLayer}
                coords={hoveredGeoCoordinate}
            />

            <LayerTooltip
                tileCoordinate={hoveredTileCoordinate}
                searchPinState={context.searchPinState}
                hoveredObject={hoveredObject}
            />

            <FeedbackPanel projectID={props.gcpProjectID} />

            <TileLayerPanelProvider>
                <OptionsPanel endpoint={props.endpoint} />
                <TileLayerPanel endpoint={props.endpoint} />
            </TileLayerPanelProvider>

            <AvoidModal positionX={x} positionY={y} />
            <AvoidPanel />

            <div
                className="histgram-panel"
                style={{
                    left: 0,
                    bottom: 0,
                    position: "absolute",
                    marginRight: "8px",
                    background: "white",
                    padding: "12px 24px",
                    boxShadow: "0 0 4px rgba(0,0,0,.15)",
                    display: "block",
                }}
            >
                <button
                    onClick={() => {
                        setShowHistogram(!showHistogram);
                    }}
                >
                    {showHistogram ? "Hide Histogram" : "Show Histogram"}
                </button>
            </div>

            {showHistogram && renderHistogram()}
        </div>
    );
};
