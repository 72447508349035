import {
    MapViewStateStorageService,
    HoveredLayerInformationStorageService,
    MapStyleStorageService,
} from "application/port/mapPort";
import { TileDefinitionsStorageService } from "application/port/tilePort";
import {
    PathInfosStorageService,
    SearchPinStateService,
} from "application/port/pathPort";
import {
    MapContextProps,
    MapContext,
    useMapStore,
} from "service/store/mapStore";
import { FeedbackDataStorageService } from "application/port/feedbackPort";

export const getMapContext = (): React.Context<MapContextProps> => MapContext;

export const useViewStateStorage = (): MapViewStateStorageService => {
    const { viewState, updateViewState } = useMapStore();
    return { viewState, updateViewState };
};

export const useTileDifinitionStorage = (): TileDefinitionsStorageService => {
    const { tileDefinitions, setTileDefinitions } = useMapStore();
    return { tileDefinitions, setTileDefinitions };
};

export const usePathInfoStorage = (): PathInfosStorageService => {
    const { pathInfos, routeColors, setPathInfos } = useMapStore();
    return { pathInfos, routeColors, setPathInfos };
};

export const useSearchPinState = (): SearchPinStateService => {
    const { searchPinState, updateSearchPinState } = useMapStore();
    return { searchPinState, updateSearchPinState };
};

export const useFeedbackDataStorage = (): FeedbackDataStorageService => {
    const {
        feedbackData,
        colorMap,
        updateFeedbackData,
        updateColorMap,
    } = useMapStore();

    return { feedbackData, colorMap, updateFeedbackData, updateColorMap };
};

export const useHoveredLayerInformation = (): HoveredLayerInformationStorageService => {
    const {
        hoveredObject,
        setHoveredObject,
        hoveredLayer,
        setHoveredLayer,
        hoveredTileCoordinate,
        setHoveredTileCoordinate,
        hoveredGeoCoordinate,
        setHoveredGeoCoordinate,
    } = useMapStore();

    return {
        hoveredObject,
        setHoveredObject,
        hoveredLayer,
        setHoveredLayer,
        hoveredTileCoordinate,
        setHoveredTileCoordinate,
        hoveredGeoCoordinate,
        setHoveredGeoCoordinate,
    };
};

export const useMapStyleStorage = (): MapStyleStorageService => {
    const { mapStyle, setMapStyle } = useMapStore();
    return { mapStyle, setMapStyle };
};
