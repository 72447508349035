import { range, memoize } from "lodash-es";

import { color } from "config";

import { TileGeoJsonFeature, TileStat } from "domain/TileGeoJsonFeature";
import { useGetTileData, getTileDataParam } from "service/tile/tileAdapter";
import {
    useAvoidListStorage,
    useSelectedAvoidListStorage,
} from "service/avoid/avoidStorageAdapter";

import * as binUtils from "utils/binUtils";
import { AvoidInformation } from "domain/AvoidInformation";

interface BinCount {
    [key: number]: number;
}

export const TileGeoJsonFeatureUsecase = () => {
    const tile = useGetTileData();
    const avoidListStorage = useAvoidListStorage();
    const selectedAvoidListStorage = useSelectedAvoidListStorage();

    const getTileData = async (
        endpoint: string,
        params: getTileDataParam,
        x: number,
        y: number,
        z: number
    ): Promise<TileGeoJsonFeature[]> => {
        const features = await tile.getTileData(endpoint, params, x, y, z);

        return features;
    };

    const getTileStat = (
        bins: number[],
        numBins: number,
        features: TileGeoJsonFeature[]
    ): TileStat => {
        const binCount = generateCounter(range(0, numBins - 1, 1));

        const stat: TileStat = features.reduce(
            (acc: { [n: number]: number }, cur: TileGeoJsonFeature) => {
                if (
                    cur.type === "Feature" &&
                    cur.properties !== null &&
                    cur.properties.col_value !== null
                ) {
                    const v = memoizedNumberToBin(
                        bins,
                        cur.properties.col_value
                    );
                    acc[v] = acc[v] + 1;
                }
                return acc;
            },
            binCount
        );

        return stat;
    };

    const getLineColor = (
        feature: TileGeoJsonFeature,
        colors: number[][],
        bins: number[]
    ): number[] => {
        if (feature.properties.col_value) {
            const c =
                colors[memoizedNumberToBin(bins, feature.properties.col_value)];
            if (c) {
                return c;
            } else {
                alert(
                    feature.properties.col_value
                    // FIXME:
                    // f.properties.col_value.toFixed(1),
                    // memoizedNumberToBin(
                    //     this.bins,
                    //     f.properties.col_value
                    // )
                );
            }
        }
        return [192, 192, 192];
    };

    return {
        getTileData,
        getTileStat,
        getLineColor,
    };
};

const memoizedNumberToBin = memoize(binUtils.numberToBin, (bins, number) => {
    return number.toFixed(1);
});

const generateCounter = (keys: number[]): BinCount => {
    const binCount: BinCount = {};
    keys.forEach((k) => {
        binCount[k] = 0;
    });
    return binCount;
};
