import { PathInfo } from "domain/PathInfo";
import { usePathInfoStorage } from "service/map/mapStorageAdapter";

export const usePathInfo = () => {
    const pathInfoStorage = usePathInfoStorage();

    const setPathInfos = (index: number, pathInfo: PathInfo): void => {
        pathInfoStorage.setPathInfos([
            ...pathInfoStorage.pathInfos.slice(0, index),
            pathInfo,
            ...pathInfoStorage.pathInfos.slice(index + 1),
        ]);
    };

    const deletePathInfos = (index: number): void => {
        const newPathInfos = [...pathInfoStorage.pathInfos];
        newPathInfos.splice(index, 1);

        // 色の並び替え
        const goBehindColor = pathInfoStorage.routeColors.splice(index, 1);
        pathInfoStorage.routeColors.push(goBehindColor[0]);

        pathInfoStorage.setPathInfos(newPathInfos);
    };

    return { setPathInfos, deletePathInfos };
};
