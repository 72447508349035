import {
    useAvoidOrganizationIdListStorage,
    useAvoidOrganizationIdStorage,
} from "service/avoid/avoidStorageAdapter";
import { useAvoid } from "service/avoid/avoidAdapter";
import { useReadableOrgIdListStorage } from "service/auth/authStorageAdapter";
import { useNotificationStorage } from "service/notification/notificationStorageAdapter";

export const useOrganizationId = () => {
    const organizationIdStorage = useAvoidOrganizationIdStorage();
    const organizationIdListStorage = useAvoidOrganizationIdListStorage();
    const { readableOrgIdList } = useReadableOrgIdListStorage();
    const avoidService = useAvoid();
    const notificationStorage = useNotificationStorage();

    const getAllOrganizations = async (): Promise<void | Error> => {
        const result = await avoidService.fetchOrganizationList();
        if (!result.isSuccess()) {
            notificationStorage.show(result.value.message, "error");
            return result.value;
        }
        const organizations = result.value;
        switch (readableOrgIdList[0]) {
            case "*":
                organizationIdListStorage.setOrganizationIdList(organizations);
                break;
            case "-":
                organizationIdListStorage.setOrganizationIdList([]);
                break;
            default: {
                const filterdOrganizations = organizations.filter((org) =>
                    readableOrgIdList.includes(org)
                );
                organizationIdListStorage.setOrganizationIdList(
                    filterdOrganizations
                );
                break;
            }
        }
    };

    const changeOrganizationId = (organizationId: string): void => {
        organizationIdStorage.setOrganizationId(organizationId);
    };

    return { getAllOrganizations, changeOrganizationId };
};
