import { AvoidTicketService } from "application/port/avoidPort";
import { Result, Success } from "domain/Result";
import {
    AvoidDateTime,
    AvoidCoordinate,
    AvoidTicket,
} from "domain/AvoidInformation";
import { getTicket, putTicket } from "service/api/freshdeskApi";

const statusMap = {
    2: "add",
    3: "add",
    4: "add",
    5: "edit",
    6: "add",
    7: "view",
    8: "add",
    9: "add",
    10: "add",
    11: "edit",
};

const veicleMap = {
    軽自動車: "CompactCar",
    小型普通車: "MediumOrdinaryCar",
    大型普通車: "HeavyOrdinaryCar",
    小型トラック: "SmallTruck",
    中型トラック: "MediumTruck",
    大型トラック: "HeavyTruck",
};

const getAvoidTicket = async (
    ticketId: number
): Promise<Result<AvoidTicket, Error>> => {
    const result = await getTicket(ticketId);

    if (!result.isSuccess()) {
        return result;
    }

    const ticketInfo = result.value;
    const ticketStatus = ticketInfo.status;
    const ticketCustomFields = ticketInfo.custom_fields;
    const vehicleType = ticketCustomFields.cf_vehicle_type;
    const avoidDate: AvoidDateTime = {
        start:
            ticketCustomFields.cf_start_date === null
                ? null
                : new Date(ticketCustomFields.cf_start_date),
        end:
            ticketCustomFields.cf_end_date === null
                ? null
                : new Date(ticketCustomFields.cf_end_date),
    };
    const avoidTime: AvoidDateTime = {
        start:
            ticketCustomFields.cf_start_time === null
                ? null
                : new Date(
                      `1999-01-01T${ticketCustomFields.cf_start_time}+09:00`
                  ),
        end:
            ticketCustomFields.cf_end_time === null
                ? null
                : new Date(
                      `1999-01-01T${ticketCustomFields.cf_end_time}+09:00`
                  ),
    };
    const coordinate: AvoidCoordinate = {
        latitude: ticketCustomFields.cf_lat,
        longitude: ticketCustomFields.cf_lon,
    };
    const avoidTicket: AvoidTicket = {
        status: statusMap[ticketStatus],
        ticketId: ticketId,
        organizationId: ticketCustomFields.cf_organization_id,
        coordinate,
        vehicleType: vehicleType === null ? null : veicleMap[vehicleType],
        date: avoidDate,
        time: avoidTime,
    };

    return new Success(avoidTicket);
};

const updateAvoidTicketStatus = async (
    ticketId: number,
    ticketStatus: number
): Promise<Result<number, Error>> => {
    const body = {
        status: ticketStatus,
    };
    const result = await putTicket(ticketId, body);

    return result;
};

export const useAvoidTicket = (): AvoidTicketService => {
    return {
        getAvoidTicket,
        updateAvoidTicketStatus,
    };
};
