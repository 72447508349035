import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { avoidPanelStyle } from "./style";
import {
    useAvoidModeStorage,
    useAvoidOrganizationIdStorage,
    useAvoidOrganizationIdListStorage,
} from "service/avoid/avoidStorageAdapter";
import { useOrganizationId } from "application/avoid/organizationUsecase";
import { useSearchAvoidTicket } from "application/avoid/avoidTicketUsecase";
import { useAvoidMode } from "application/avoid/avoidModeUsecase";
import { AvoidMode } from "domain/AvoidInformation";
import { useAvoidService } from "application/avoid/avoidUsecase";

export const AvoidPanel = () => {
    const classes = avoidPanelStyle();
    const { mode } = useAvoidModeStorage();
    const { organizationId } = useAvoidOrganizationIdStorage();
    const { organizationIdList } = useAvoidOrganizationIdListStorage();
    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    const [inputTicketId, setInputTicketId] = useState<string>("");

    const ticketService = useSearchAvoidTicket();
    const organizationIdUsecase = useOrganizationId();
    const avoidModeUsecase = useAvoidMode();
    const avoidUsecase = useAvoidService();

    const [loading, setLoading] = React.useState(false);

    const handleChangeIsEnabled = () => {
        if (isEnabled) {
            setIsEnabled(false);
        } else {
            setIsEnabled(true);
            setLoading(true);
            organizationIdUsecase.getAllOrganizations().then((err) => {
                setLoading(false);
                if (err) {
                    setIsEnabled(false);
                }
            });
        }
    };

    const handleChangeInputTicketId = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInputTicketId(event.target.value);
    };

    const searchTicket = () => {
        setLoading(true);
        ticketService.searchAvoidTicket(Number(inputTicketId)).then(() => {
            setLoading(false);
        });
    };

    const handleChangeMode = (event: React.ChangeEvent<{ value: unknown }>) => {
        avoidModeUsecase.changeAvoidMode(event.target.value as AvoidMode);
    };

    const handleChangeOrganizationId = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        organizationIdUsecase.changeOrganizationId(
            event.target.value as string
        );
        avoidUsecase.viewAvoid("", event.target.value as string, false);
    };

    return (
        <div className={classes.panel}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography className={classes.typography} variant="h5">
                Avoid
            </Typography>
            <Button
                className={classes.button}
                variant="outlined"
                onClick={handleChangeIsEnabled}
            >
                {isEnabled ? "- Disabled" : "+ Enable"}
            </Button>
            {isEnabled ? (
                <>
                    <div className={classes.inputTicket}>
                        <InputBase
                            className={classes.input}
                            placeholder="Search TicketID"
                            value={inputTicketId}
                            onChange={handleChangeInputTicketId}
                        />
                        <IconButton
                            className={classes.iconButton}
                            onClick={searchTicket}
                        >
                            <SearchIcon />
                        </IconButton>
                    </div>
                    <div>
                        <InputLabel>Mode</InputLabel>
                        <Select
                            value={mode}
                            onChange={handleChangeMode}
                            className={classes.selectbox}
                        >
                            <MenuItem value="view">View</MenuItem>
                            <MenuItem value="add">Add</MenuItem>
                            <MenuItem value="edit">Edit</MenuItem>
                            <MenuItem value="remove">Remove</MenuItem>
                        </Select>
                    </div>
                    <div>
                        <InputLabel>Organization</InputLabel>
                        <Select
                            value={organizationId}
                            onChange={handleChangeOrganizationId}
                            className={classes.selectbox}
                        >
                            {organizationIdList.map((organizationId) => (
                                <MenuItem
                                    value={organizationId}
                                    key={organizationId}
                                >
                                    {organizationId}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </>
            ) : null}
        </div>
    );
};
