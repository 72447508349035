import React from "react";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useNotificationStorage } from "service/notification/notificationStorageAdapter";

export const CustomizedSnackbar = () => {
    const { open, message, severity, close } = useNotificationStorage();

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        close();
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <MuiAlert
                onClose={handleClose}
                elevation={6}
                variant="filled"
                severity={severity}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};
