import { gapi } from "gapi-script";

import { appConfig } from "../config";

const CLIENTID = appConfig.GCP_CLIENT_ID;

let done = false;

export const initClient = (options: {
    updateLoggedInStatus: (status: boolean) => void;
}) => {
    if (done) {
        return;
    }
    done = true;
    gapi.client
        .init({
            clientId: CLIENTID,
            scope: "https://www.googleapis.com/auth/bigquery",
        })
        .then(() => {
            gapi.auth2
                .getAuthInstance()
                .isSignedIn.listen(options.updateLoggedInStatus);

            options.updateLoggedInStatus(
                gapi.auth2.getAuthInstance().isSignedIn.get()
            );
        })
        .catch((err: any) => {
            console.error("gapi client init error:", err);
        });
};

export const signin = () => {
    gapi.auth2.getAuthInstance().signIn();
};

export const signout = () => {
    gapi.auth2.getAuthInstance().signOut();
};

export const getProject = () => {
    gapi.client
        .request({
            path:
                "https://www.googleapis.com/bigquery/v2/projects?maxResults=100000",
        })
        .then((response: any) => {
            console.log(response.result.projects);
            return response.result.projects;
        })
        .catch((err: any) => {
            console.error("gapi get project error:", err);
            return [];
        });
};
