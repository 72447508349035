import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { routeSearchConfig } from "config";
import { PathInfo } from "domain/PathInfo";
import { useSearchPath } from "application/path/pathUsecase";
import { usePathInfo } from "application/path/pathInfoUsecase";
import { usePathInfoStorage } from "service/map/mapStorageAdapter";

import RouteSearchBox from "./RouteSearchBox";
import { getUnixTime } from "utils/otherUtils";
import { useSearchPanelStyle } from "./style";

interface Props {
    endpoint: string;
}

const RouteSearchPanel = (props: Props) => {
    const classes = useSearchPanelStyle({ color: "" });

    const [showSearchPanel, setShowSearchPanel] = useState(true);

    const { pathInfos, routeColors } = usePathInfoStorage();
    const pathUsecase = useSearchPath();
    const pathInfoUsecase = usePathInfo();

    const searchPath = async (index: number): Promise<void> => {
        const res = await pathUsecase.searchPath(
            props.endpoint,
            pathInfos[index]
        );

        const pathInfo = pathInfos[index];
        pathInfo.pathData = res.pathData;
        pathInfo.pathTime = res.pathTime;

        pathInfoUsecase.setPathInfos(index, pathInfo);
    };

    const deletePathLayer = (index: number): void => {
        pathInfoUsecase.deletePathInfos(index);
    };

    const addRouteSearch = () => {
        const index = pathInfos.length;

        const newPathInfo = new PathInfo(
            Object.assign({}, routeSearchConfig.INIT_PARAM.start),
            Object.assign({}, routeSearchConfig.INIT_PARAM.goal),
            getUnixTime(),
            routeColors[index % 10]
        );
        pathInfoUsecase.setPathInfos(index, newPathInfo);
    };

    const renderRouteSearchPanel = (index) => {
        return (
            <RouteSearchBox
                index={index}
                searchPath={searchPath}
                deletePathLayer={deletePathLayer}
            />
        );
    };

    const showPanelHandler = () => {
        if (pathInfos.length > 0) {
            setShowSearchPanel(!showSearchPanel);
        } else {
            alert("plesse add route");
        }
    };

    return (
        <div className={classes.panel}>
            <Typography className={classes.typography} variant="h5">
                Route Search
            </Typography>
            {pathInfos.map(
                (_, index) =>
                    showSearchPanel && (
                        <div key={index}>{renderRouteSearchPanel(index)}</div>
                    )
            )}
            <Button className={classes.button} onClick={addRouteSearch}>
                + ADD ROUTE
            </Button>
            <Button className={classes.button} onClick={showPanelHandler}>
                {showSearchPanel ? "hide" : "show"}
            </Button>
        </div>
    );
};

export default RouteSearchPanel;
