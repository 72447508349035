import React, { createContext, useContext, useState } from "react";
import { Color } from "@material-ui/lab/Alert";

export interface NotificationContextProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    severity: Color;
    setSeverity: React.Dispatch<React.SetStateAction<Color>>;
}

export const NotificationContext = createContext<NotificationContextProps>(
    {} as NotificationContextProps
);

export const useNotificationStore = (): NotificationContextProps =>
    useContext(NotificationContext);

export const NotificationProvider: React.FC = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<Color>("error");

    const value: NotificationContextProps = {
        open,
        setOpen,
        message,
        setMessage,
        severity,
        setSeverity,
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};
