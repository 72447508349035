import React, { useState, useEffect } from "react";
import { gapi } from "gapi-script";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { initClient, signin } from "../../utils/gapiClient.service";
import { headerStyle } from "./style";
import { useAuthService } from "application/auth/authUsecase";
import { MapStyleSelect } from "./MapStyleSelect";

interface Props {
    endpoint?: string;
}

const Header = (props: Props) => {
    const classes = headerStyle();

    const authUsecase = useAuthService();

    const [loggedInStatus, setLoggedInStatus] = useState<boolean>(false);
    const [initiatedClient, setInitiatedClient] = useState<boolean>(false);

    useEffect(() => {
        gapi.load("client:auth2", () =>
            initClient({
                updateLoggedInStatus: (status) => {
                    setLoggedInStatus(status);
                },
            })
        );
        setInitiatedClient(true);
    }, [initiatedClient]);

    const authorizeGoogle = () => {
        if (loggedInStatus) {
            window.alert("すでにログインしています");
            return;
        }
        signin();
    };

    const handleLogout = () => {
        authUsecase.logout().then((err) => {
            if (err) {
                alert(err);
            }
        });
    };

    return (
        <AppBar className={classes.appbar} position="static">
            <Toolbar>
                <Typography className={classes.typography} variant="h4">
                    Mappit
                </Typography>

                <MapStyleSelect />

                <Button onClick={authorizeGoogle}>google auth</Button>

                <Button onClick={handleLogout}>logout</Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
