import React, { Fragment, useState, useEffect } from "react";

import { Box, Button, Input, Select, MenuItem } from "@material-ui/core";
import Modal from "react-modal";

import { useViewState } from "application/map/viewStateUsecase";
import { useTileTableStorage } from "service/tile/tileLayerPanelStorageAdapter";
import { optionPanelStyle } from "./style";
import { useNotificationStorage } from "service/notification/notificationStorageAdapter";

interface LoadLookupError {
    message: string;
}

interface Props {
    endpoint: string;
}

const OptionsPanel = (props: Props) => {
    const classes = optionPanelStyle();

    const viewStateUsecase = useViewState();
    const { tables } = useTileTableStorage();

    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [latitude, setLatitude] = useState<number>(35.158027917004254);
    const [longitude, setLongitude] = useState<number>(136.89783597442766);
    const [roadID, setRoadID] = useState<number>(0);
    const [tablename, setTablename] = useState<string>("");

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<LoadLookupError>({
        message: "",
    });

    const notificationStorageService = useNotificationStorage();

    const roadLookup = async () => {
        try {
            await viewStateUsecase.lookupRoad(
                props.endpoint,
                tablename,
                String(roadID)
            );
        } catch (e) {
            notificationStorageService.show("invalid roadid", "error");
        }
    };

    const applyViewState = () => {
        viewStateUsecase.updatePoint({
            latitude,
            longitude,
            zoom: 15,
        });
    };

    return (
        <Box className={classes.panel}>
            <Button onClick={() => setShowPanel(!showPanel)}>
                {showPanel ? "hide" : "show"}
            </Button>

            {showPanel && (
                <Fragment>
                    <Box className={classes.box}>
                        column to color on:
                        <Input value="" type="text" />
                        numberOfSpeedBins:
                        <Input
                            value=""
                            name="numberOfSpeedBins"
                            type="number"
                        />
                        maxSpeed:
                        <Input value="" name="maxSpeed" type="number" />
                        <Button>apply</Button>
                    </Box>
                    <Box className={classes.box}>
                        latitude:
                        <Input
                            name="lat"
                            value={latitude}
                            onChange={(e) =>
                                setLatitude(parseFloat(e.target.value))
                            }
                            type="number"
                        />
                        longitude:
                        <Input
                            name="maxSpeed"
                            value={longitude}
                            onChange={(e) =>
                                setLongitude(parseFloat(e.target.value))
                            }
                            type="number"
                        />
                        <Button
                            onClick={() => {
                                applyViewState();
                            }}
                        >
                            apply
                        </Button>
                    </Box>
                    <Box className={classes.box}>
                        table:
                        <Select
                            defaultValue="default"
                            style={{ backgroundColor: "#fff" }}
                            value={tablename}
                            onChange={(val) =>
                                setTablename(val.target.value as string)
                            }
                        >
                            <MenuItem disabled value="default">
                                <em>None</em>
                            </MenuItem>
                            {tables.map((_tablename, index) => (
                                <MenuItem key={index} value={_tablename}>
                                    {_tablename}
                                </MenuItem>
                            ))}
                        </Select>
                        roadid:
                        <Input
                            name="roadid"
                            value={roadID}
                            onChange={(e) =>
                                setRoadID(parseInt(e.target.value))
                            }
                            error={notificationStorageService.open}
                            type="number"
                        />
                        <Button
                            onClick={() => {
                                roadLookup();
                            }}
                        >
                            search
                        </Button>
                    </Box>
                </Fragment>
            )}

            <Modal className={classes.errorModal} isOpen={modalIsOpen}>
                <h2>ERROR</h2>
                <div>message: {modalMessage.message}</div>
            </Modal>
        </Box>
    );
};

export default OptionsPanel;
