import { MapPoint } from "domain/MapViewState";
import { AvoidInformation, VehicleType } from "domain/AvoidInformation";
import { useViewState } from "application/map/viewStateUsecase";
import { useAvoidTicket } from "service/avoid/avoidTicketAdapter";
import {
    useAvoidModeStorage,
    useAvoidOrganizationIdListStorage,
    useAvoidOrganizationIdStorage,
    useTicketAvoidStorage,
} from "service/avoid/avoidStorageAdapter";
import { useAvoidService } from "./avoidUsecase";
import { useReadableOrgIdListStorage } from "service/auth/authStorageAdapter";
import { useNotificationStorage } from "service/notification/notificationStorageAdapter";

export const useSearchAvoidTicket = () => {
    const viewStateUsecase = useViewState();
    const avoidUsecase = useAvoidService();
    const avoidTicket = useAvoidTicket();
    const ticketAvoidStorage = useTicketAvoidStorage();
    const avoidMode = useAvoidModeStorage();
    const avoidOrganizationIdStorage = useAvoidOrganizationIdStorage();
    const {
        organizationIdList,
        setOrganizationIdList,
    } = useAvoidOrganizationIdListStorage();
    const { readableOrgIdList } = useReadableOrgIdListStorage();
    const notificationStorage = useNotificationStorage();

    const searchAvoidTicket = async (ticketId: number): Promise<void> => {
        const result = await avoidTicket.getAvoidTicket(ticketId);
        if (!result.isSuccess()) {
            notificationStorage.show(result.value.message, "error");
            return;
        }

        if (
            readableOrgIdList[0] !== "*" &&
            !organizationIdList.includes(result.value.organizationId)
        ) {
            const error = new Error("permission denied");
            notificationStorage.show(error.message, "error");
            return;
        }

        const ticketStatus = result.value.status;
        avoidMode.setMode(ticketStatus);

        const coordinate = result.value.coordinate;

        const avoidInformation: AvoidInformation = {
            ticketId,
            organizationId: result.value.organizationId,
            coordinate,
            vehicleType: result.value.vehicleType as VehicleType,
            date: result.value.date,
            time: result.value.time,
        };
        ticketAvoidStorage.setTicketAvoid(avoidInformation);

        if (!organizationIdList.includes(avoidInformation.organizationId)) {
            setOrganizationIdList([
                ...organizationIdList,
                avoidInformation.organizationId,
            ]);
        }

        avoidOrganizationIdStorage.setOrganizationId(
            avoidInformation.organizationId
        );
        avoidUsecase.viewAvoid("", avoidInformation.organizationId, false);
        // update viewstate
        const newMapPoint: MapPoint = {
            latitude: Number(coordinate.latitude),
            longitude: Number(coordinate.longitude),
            zoom: 15,
        };
        viewStateUsecase.updatePoint(newMapPoint);
    };

    return { searchAvoidTicket };
};
