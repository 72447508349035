import { makeStyles, createStyles, Theme } from "@material-ui/core";

// import { color } from "config";

export const histgramStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            right: 0,
            bottom: 0,
            position: "absolute",
            marginRight: 8,
            backgroundColor: "rgba(0,0,0,.5)",
            padding: "12px 24px",
            boxShadow: "0 0 4px rgba(0,0,0,.15)",
            display: "block",
        },
    })
);
