import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

export const optionPanelStyle = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            top: "9%",
            right: 0,
            position: "absolute",
            marginRight: "8px",
            background: color.backgroud.panel,
            padding: "12px 24px",
            display: "block",

            "& Button": {
                backgroundColor: color.backgroud.button,
                height: 15,
            },

            "& Input": {
                background: "#fff",
                height: 10,
            },
        },
        box: {
            background: "#cacde7",
            position: "relative",
            display: "inline-grid",
        },
        errorModal: {
            position: "absolute",
            top: "35%",
            left: "50%",
            display: "block",
            padding: 10,
            backgroundColor: "#fff",
            borderRadius: "1em",
            transform: "translate(-50%, -50%)",
            outline: "transparent",
            textAlign: "center",
        },
    })
);
