import { getUnixTime } from "utils/otherUtils";

export const appConfig = {
    ENV: process.env.NODE_ENV,
    BACKEND_ENDPOINT: process.env.REACT_APP_BACKEND_ENDPOINT_PRD,
    BACKEND_ENDPOINTS: [
        process.env.REACT_APP_BACKEND_ENDPOINT_LOCAL,
        process.env.REACT_APP_BACKEND_ENDPOINT_STG,
        process.env.REACT_APP_BACKEND_ENDPOINT_PRD,
    ],
    GCP_CLIENT_ID: process.env.REACT_APP_GCP_CLIENT_ID,
    GCP_PROJECT_ID: process.env.REACT_APP_GCP_PROJECT_ID,
    MAPBOX_ACCESS_TOKEN:
        "pk.eyJ1IjoiaHVnaGV5bSIsImEiOiJjanc4bHlwYzYwNjEzNDNxbXYxeGFsZnFhIn0.yyUhWfZZekctPutK4epO_A",
    AVOID_API_URL: process.env.REACT_APP_AVOIDAPI_URL,
    AVOID_API_KEY: process.env.REACT_APP_AVOIDAPI_KEY,
    FRESHDESK_URL: process.env.REACT_APP_FRESHDESK_URL,
    FRESHDESK_APIKEY: process.env.REACT_APP_FRESHDESK_APIKEY,
    COGNITO_USER_POOL_REGION: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    COGNITO_USER_POOL_WEB_CLIENT_ID:
        process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    COGNITO_OAUTH_DOMAIN: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    COGNITO_OAUTH_REDIRECT_SIGNIN_URL:
        process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN_URL,
    COGNITO_OAUTH_REDIRECT_SIGNOUT_URL:
        process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT_URL,
    BACKEND_KEY: process.env.REACT_APP_BACKEND_KEY,
    BACKEND_PASSWORD: process.env.REACT_APP_BACKEND_PASSWORD,
};

export const initialViewState = {
    MAP_VIEWSTATE: {
        latitude: 35.158027917004254,
        longitude: 136.89783597442766,
        zoom: 15,
        bearing: 0,
        pitch: 30,
        transitionDuration: 10,
    },
    RASTER_VIEWSTATE: {
        latitude: 35.708333,
        longitude: 138.733405,
        zoom: 18,
        bearing: 0,
        pitch: 30,
        transitionDuration: 10,
    },
};

export const routeSearchConfig = {
    INIT_PARAM: {
        start: {
            latitude: 35.158027917004254,
            longitude: 136.89783597442766,
            preferredSide: "either",
        },
        goal: {
            latitude: 35.16052005172999,
            longitude: 136.88807808861745,
            preferredSide: "either",
        },
        datetime: getUnixTime(),
    },
};

export const color = {
    backgroud: {
        header: "rgba(160, 160, 160, 0.8)",
        panel: "rgba(160, 160, 160, 0.8)",
        box: "rgba(240, 240, 240, 0.8)",
        button: "rgba(240, 240, 240, 1.0)",
    },
    font: {
        typography: "#1f1f1f",
    },
    avoidLayer: [255, 0, 0],
    selectedAvoid: [0, 0, 255],
};
