import { appConfig } from "config";
import { fetchWrapper } from "./wrapper";
import { Result, Success, Failure } from "domain/Result";
import { dummyRawAvoids } from "./dummyAvoids";

// TODO: remove 'export' (for dummyAvoids)
export type RawRoad = {
    objectid: number;
    from_node_id: number;
    to_node_id: number;
    roadclass_c: number;
    naviclass_c: number;
    linkclass_c: number;
    roadmanager_c: number;
    other_attributes: number;
    avoidIds: number[];
};

export type RawAvoid = {
    avoidId: number;
    organizationId: string;
    ticketId: number;
    linkId: number;
    linkShape: string;
    linkMidLat: number;
    linkMidLon: number;
    mapVersion: string;
    vehicleType: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    heading: boolean;
    deletedAt: string;
    createdAt: string;
    updatedAt: string;
};

type ErrorType = {
    errorMessage: string;
    errorCode: string;
};

type ResponseGetRoad = {
    road: RawRoad;
    error: ErrorType;
};

type ResponseGetAvoid = {
    avoids: RawAvoid[];
    error: ErrorType;
};

type ResponsePostAvoid = {
    status: string;
    avoidId: number;
    error: ErrorType;
};

type ResponseEditAvoid = {
    status: string;
    error: ErrorType;
};

type ResponseGetOrganizations = {
    organizations: string[];
    error: ErrorType;
};

type ParamsGetRoads = {
    mapVersion: string;
};

type ParamsGetAvoids = {
    organization: string;
    bbox: string;
    deleted: boolean;
};

export type RequestPostAvoid = {
    organization: string;
    ticketId: number;
    linkId: number;
    mapVersion: string | null;
    vehicleType: string | null;
    startDate: string | null;
    endDate: string | null;
    startTime: string | null;
    endTime: string | null;
    heading: string | null;
};

export const getRoads = async (
    linkId: number,
    params: ParamsGetRoads
): Promise<Result<number[], Error>> => {
    const queryParams = new URLSearchParams(params);
    const endpoint = `${appConfig.AVOID_API_URL}/roads/${linkId}?${queryParams}`;
    const init: RequestInit = {
        headers: {
            "Content-Type": "application/json",
            "x-api-key": `${appConfig.AVOID_API_KEY}`,
        },
    };

    return fetchWrapper<ResponseGetRoad>(endpoint, init)
        .then((resJson) => {
            const err = resJson.error;
            if (err.errorCode) {
                throw new Error(err.errorMessage);
            }

            const avoidIds = resJson.road.avoidIds;

            return new Success(avoidIds);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};

export const getAvoids = async (
    params: ParamsGetAvoids
): Promise<Result<RawAvoid[], Error>> => {
    const queryParams =
        params.bbox === ""
            ? new URLSearchParams({
                  deleted: params.deleted ? "True" : "False",
                  organization: params.organization,
              })
            : new URLSearchParams({
                  ...params,
                  deleted: params.deleted ? "True" : "False",
              });
    const endpoint = `${appConfig.AVOID_API_URL}/map/v0/avoids?${queryParams}`;
    const init: RequestInit = {
        headers: {
            "Content-Type": "application/json",
            "x-api-key": `${appConfig.AVOID_API_KEY}`,
        },
    };

    return fetchWrapper<ResponseGetAvoid>(endpoint, init)
        .then((resJson) => {
            const err = resJson.error;
            if (err.errorCode) {
                throw new Error(err.errorMessage);
            }

            return new Success(resJson.avoids);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};

export const postAvoid = async (
    linkId: number,
    body: RequestPostAvoid,
    idToken: string,
    organization: string
): Promise<Result<number, Error>> => {
    const endpoint = `${appConfig.AVOID_API_URL}/map/v0/links/${linkId}/avoids`;
    const init: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "x-api-key": `${appConfig.AVOID_API_KEY}`,
            Authorization: idToken,
            Organization: organization,
        },
        body: JSON.stringify(body),
    };

    return fetchWrapper<ResponsePostAvoid>(endpoint, init)
        .then((resJson) => {
            const err = resJson.error;
            if (err.errorCode) {
                throw new Error(err.errorMessage);
            }

            return new Success(resJson.avoidId);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};

export const deleteAvoid = async (
    avoidId: number,
    idToken: string,
    organization: string
): Promise<Result<null, Error>> => {
    const endpoint = `${appConfig.AVOID_API_URL}/map/v0/avoids/${avoidId}`;
    const init: RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-api-key": `${appConfig.AVOID_API_KEY}`,
            Authorization: idToken,
            Organization: organization,
        },
    };

    return fetchWrapper<ResponseEditAvoid>(endpoint, init)
        .then((resJson) => {
            const err = resJson.error;
            if (err.errorCode) {
                throw new Error(err.errorMessage);
            }

            return new Success(null);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};

export const putAvoid = async (
    avoidId: number,
    body: RequestPostAvoid,
    idToken: string,
    organization: string
): Promise<Result<null, Error>> => {
    const endpoint = `${appConfig.AVOID_API_URL}/map/v0/avoids/${avoidId}`;
    const init: RequestInit = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-api-key": `${appConfig.AVOID_API_KEY}`,
            Authorization: idToken,
            Organization: organization,
        },
        body: JSON.stringify(body),
    };

    return fetchWrapper<ResponseEditAvoid>(endpoint, init)
        .then((resJson) => {
            const err = resJson.error;
            if (err.errorCode) {
                throw new Error(err.errorMessage);
            }

            return new Success(null);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};

export const fetchOrganizations = async (): Promise<
    Result<string[], Error>
> => {
    const endpoint = `${appConfig.AVOID_API_URL}/map/v0/organizations?mode=avoid`;
    const init: RequestInit = {
        headers: {
            "x-api-key": `${appConfig.AVOID_API_KEY}`,
        },
    };

    return fetchWrapper<ResponseGetOrganizations>(endpoint, init)
        .then((resJson) => {
            const err = resJson.error;
            if (err.errorCode) {
                throw new Error(err.errorMessage);
            }

            const organizations = resJson.organizations;

            return new Success(organizations);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};
