import { gapi } from "gapi-script";

import {
    FeedbackData,
    RawFeedbackData,
    BigQueryColumn,
} from "domain/FeedbackData";

import { get_fb_query } from "utils/queries";

const ColumnType = {
    STRING: "string",
    NUMBER: "number",
    LAT: "latitude",
    LNG: "longitude",
    WKT: "wkt",
    DATE: "date",
    ID: "id",
};

interface ColumnStat {
    min: number;
    max: number;
    nulls: number;
}

interface BigQueryResponse {
    f: { v: string }[];
}

export const runGetFeedbackQuery = (
    projectID: string,
    startDate: string,
    endDate: string
): Promise<{
    columns: BigQueryColumn[];
    columnNames: string[];
    rows: RawFeedbackData[];
}> => {
    const query = get_fb_query(startDate, endDate);

    const body = {
        query: query,
        maxResults: 5000000,
        timeoutMs: 120000,
        useLegacySql: false,
    };

    return gapi.client
        .request({
            path: `https://www.googleapis.com/bigquery/v2/projects/${projectID}/queries`,
            method: "POST",
            body,
        })
        .then((response) => {
            const stats: Map<string, ColumnStat> = new Map();

            if (response.result.jobComplete === false) {
                throw new Error("Request timed out after ${120000 / 1000}");
            }

            if (response.result.totalRows === "0") {
                throw new Error("No results.");
            }

            if (response.result.rows.length === 0) {
                throw new Error("No results.");
            }

            // Normalize column types.
            const columnNames: string[] = [];
            const columns: BigQueryColumn[] = (
                response.result.schema.fields || []
            ).map(
                (field: BigQueryColumn): BigQueryColumn => {
                    if (isNumericField(field)) {
                        field.type = ColumnType.NUMBER;
                        stats.set(field.name, {
                            min: Infinity,
                            max: -Infinity,
                            nulls: 0,
                        });
                    } else {
                        field.type = ColumnType.STRING;
                    }
                    columnNames.push(field.name);

                    return field;
                }
            );

            // Normalize row structure.
            const rows = normalizeRows(response.result.rows, columns, stats);

            if (rows.length === 0) {
                throw new Error("No results.");
            }

            return { columns, columnNames, rows };
        });
};

const isNumericField = (field: BigQueryColumn): boolean => {
    const fieldType = field["type"].toUpperCase();

    return ["INTEGER", "NUMBER", "FLOAT", "DECIMAL"].includes(fieldType);
};

const normalizeRows = (
    rows: BigQueryResponse[],
    normalizedCols: BigQueryColumn[],
    stats: Map<string, ColumnStat>
) => {
    return (rows || []).map((row) => {
        const rowObject = {};
        row["f"].forEach(({ v }, index) => {
            const column = normalizedCols[index];

            if (column["type"] === ColumnType.NUMBER) {
                const new_v = v === "" || v === null ? null : Number(v);

                rowObject[column["name"]] = new_v;
                const stat = stats.get(column["name"]);

                if (new_v === null && stat !== undefined) {
                    stat.nulls++;
                } else if (new_v !== null && stat !== undefined) {
                    stat.max =
                        Math.round(Math.max(stat.max, new_v) * 1000) / 1000;
                    stat.min =
                        Math.round(Math.min(stat.min, new_v) * 1000) / 1000;
                }
            } else {
                rowObject[column["name"]] = v === null ? null : String(v);
            }
        });

        return rowObject;
    });
};
