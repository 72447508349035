import React, { createContext, useContext, useState } from "react";

import { MapViewState } from "domain/MapViewState";
import { TileDefinitions } from "domain/TileDefinition";
import { PathInfo } from "domain/PathInfo";
import { FeedbackData } from "domain/FeedbackData";
import { SearchPinState } from "application/path/SearchPinState";
import { getRouteColors } from "utils/colorUtils";
import { initialViewState } from "config";
import {
    GeoCoordinate,
    HoveredLayer,
    HoveredObject,
    TileCoordinate,
} from "domain/LayerInformation";

export interface MapContextProps {
    viewState: MapViewState;
    updateViewState: React.Dispatch<React.SetStateAction<MapViewState>>;

    tileDefinitions: TileDefinitions;
    setTileDefinitions: React.Dispatch<React.SetStateAction<TileDefinitions>>;

    pathInfos: PathInfo[];
    routeColors: number[][];
    setPathInfos: React.Dispatch<React.SetStateAction<PathInfo[]>>;

    searchPinState: SearchPinState;
    updateSearchPinState: React.Dispatch<React.SetStateAction<SearchPinState>>;

    feedbackData: FeedbackData[];
    colorMap: Map<string, number[]>;
    updateFeedbackData: React.Dispatch<React.SetStateAction<FeedbackData[]>>;
    updateColorMap: React.Dispatch<React.SetStateAction<Map<string, number[]>>>;

    hoveredObject: HoveredObject | null;
    setHoveredObject: React.Dispatch<
        React.SetStateAction<HoveredObject | null>
    >;
    hoveredLayer: HoveredLayer | null;
    setHoveredLayer: React.Dispatch<React.SetStateAction<HoveredLayer | null>>;
    hoveredTileCoordinate: TileCoordinate | null;
    setHoveredTileCoordinate: React.Dispatch<
        React.SetStateAction<TileCoordinate | null>
    >;
    hoveredGeoCoordinate: GeoCoordinate | null;
    setHoveredGeoCoordinate: React.Dispatch<
        React.SetStateAction<GeoCoordinate | null>
    >;
    mapStyle: string;
    setMapStyle: React.Dispatch<React.SetStateAction<string>>;
}

export const MapContext = createContext<MapContextProps>({} as MapContextProps);
export const useMapStore = (): MapContextProps => useContext(MapContext);

export const MapProvider: React.FC = ({ children }) => {
    const [viewState, setViewState] = useState<MapViewState>(
        initialViewState.MAP_VIEWSTATE
    );
    const [tileDefinitions, setTileDefinitions] = useState<TileDefinitions>({});
    const [pathInfos, setPathInfos] = useState<PathInfo[]>([]);
    const [searchPinState, setSearchPinState] = useState<SearchPinState>(
        new SearchPinState(0, false, true)
    );
    const [feedbackData, setFeedbackData] = useState<FeedbackData[]>([]);
    const [colorMap, setColorMap] = useState<Map<string, number[]>>(new Map());

    const [hoveredObject, setHoveredObject] = useState<HoveredObject | null>(
        null
    );
    const [hoveredLayer, setHoveredLayer] = useState<HoveredLayer | null>(null);
    const [
        hoveredTileCoordinate,
        setHoveredTileCoordinate,
    ] = useState<TileCoordinate | null>(null);
    const [
        hoveredGeoCoordinate,
        setHoveredGeoCoordinate,
    ] = useState<GeoCoordinate | null>(null);
    const [mapStyle, setMapStyle] = useState<string>("dark-v10");

    const value: MapContextProps = {
        viewState,
        updateViewState: setViewState,

        tileDefinitions,
        setTileDefinitions,

        pathInfos,
        routeColors: getRouteColors(),
        setPathInfos,

        searchPinState,
        updateSearchPinState: setSearchPinState,

        feedbackData: feedbackData,
        colorMap: colorMap,
        updateFeedbackData: setFeedbackData,
        updateColorMap: setColorMap,

        hoveredObject,
        setHoveredObject,
        hoveredLayer,
        setHoveredLayer,
        hoveredTileCoordinate,
        setHoveredTileCoordinate,
        hoveredGeoCoordinate,
        setHoveredGeoCoordinate,

        mapStyle,
        setMapStyle,
    };

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};
