import {
    loginToMappitBackend,
    logoutOfMappitBackend,
} from "service/api/authApi";
import { Result } from "domain/Result";
import { AuthService } from "application/port/authPort";

const login = async (endpoint: string): Promise<Result<null, Error>> => {
    return await loginToMappitBackend(endpoint);
};

const logout = async (endpoint: string): Promise<Result<null, Error>> => {
    return await logoutOfMappitBackend(endpoint);
};

export const useAuth = (): AuthService => {
    return {
        login,
        logout,
    };
};
