import {
    TileTablesStorageService,
    TileColorColumnsStorageService,
    TileShapeColumnsStorageService,
} from "application/port/tilePort";
import { useTileLayerPanelStore } from "service/store/tileLayerPanelStore";

export const useTileTableStorage = (): TileTablesStorageService => {
    const { tables, updateTables } = useTileLayerPanelStore();
    return { tables, updateTables };
};

export const useTileColorColumnsStorage = (): TileColorColumnsStorageService => {
    const { colorColumns, updateColorColumns } = useTileLayerPanelStore();
    return { colorColumns, updateColorColumns };
};

export const useTileShapeColumnsStorage = (): TileShapeColumnsStorageService => {
    const { shapeColumns, updateShapeColumns } = useTileLayerPanelStore();
    return { shapeColumns, updateShapeColumns };
};
