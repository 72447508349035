import {
    BackendUrlStorageService,
    IdTokenStorageService,
    OrganizationStorageService,
    ReadableOrgIdListStorageService,
} from "application/port/authPort";
import { useAuthStore } from "service/store/authStore";

export const useIdTokenStorage = (): IdTokenStorageService => {
    const { idToken, setIdToken } = useAuthStore();
    return { idToken, setIdToken };
};

export const useOrganizationStorage = (): OrganizationStorageService => {
    const { organization, setOrganization } = useAuthStore();
    return { organization, setOrganization };
};

export const useReadableOrgIdListStorage = (): ReadableOrgIdListStorageService => {
    const { readableOrgIdList, setReadableOrgIdList } = useAuthStore();
    return { readableOrgIdList, setReadableOrgIdList };
};

export const useBackendUrlStorageService = (): BackendUrlStorageService => {
    const { backendUrl, setBackendUrl } = useAuthStore();
    return { backendUrl, setBackendUrl };
};
