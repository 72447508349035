import { Failure, Result, Success } from "domain/Result";
import { fetchWrapper } from "./wrapper";
import { appConfig } from "../../config";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const crypto = require("crypto");

const BACKEND_KEY = appConfig.BACKEND_KEY;
const BACKEND_PASSWORD = appConfig.BACKEND_PASSWORD;

export const loginToMappitBackend = async (
    endpoint: string
): Promise<Result<null, Error>> => {
    const encrypt = crypto.createCipher("aes256", BACKEND_KEY);
    encrypt.update(BACKEND_PASSWORD, "utf8", "hex");
    const encryptionPassword = encrypt.final("hex");

    const data = { password: encryptionPassword };

    const init: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    return fetchWrapper(endpoint + "/auth/login", init)
        .then(() => {
            console.log("login to", endpoint);
            return new Success(null);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};

export const logoutOfMappitBackend = async (
    endpoint: string
): Promise<Result<null, Error>> => {
    const init: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    return fetchWrapper(endpoint + "/auth/logout", init)
        .then(() => {
            return new Success(null);
        })
        .catch((err) => {
            console.log(err);
            return new Failure(err);
        });
};
