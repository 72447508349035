import React from "react";

import DeckGL from "@deck.gl/react";
import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";

import { appConfig, initialViewState } from "../../config";

const BACKEND_ENDPOINT = appConfig.REACT_APP_BACKEND_ENDPOINT;
const INITIAL_VIEW_STATE = initialViewState.RASTER_VIEWSTATE;

function getTooltip({ tile }) {
    return tile && `tile: x: ${tile.x}, y: ${tile.y}, z: ${tile.z}`;
}

const layer = new TileLayer({
    // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
    data: BACKEND_ENDPOINT + "/raster/getTile/{z}/{x}/{y}.webp",

    minZoom: 0,
    maxZoom: 18,
    tileSize: 256,

    renderSubLayers: (props) => {
        const {
            bbox: { west, south, east, north },
        } = props.tile;

        const bitmap_layer = new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north],
        });

        return bitmap_layer;
    },
});

const RasterMap = () => {
    return (
        <DeckGL
            initialViewState={INITIAL_VIEW_STATE}
            layers={[layer]}
            // views={new MapView({ repeat: true })}
            controller={true}
            getTooltip={getTooltip}
        />
    );
};

export default RasterMap;
