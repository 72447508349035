import React, { createContext, useContext, useState } from "react";

import { TileTables, TileColumns } from "domain/TileInformation";

interface TileLayerPanelContextProps {
    tables: TileTables;
    updateTables: (tables: TileTables) => void;

    colorColumns: TileColumns;
    updateColorColumns: React.Dispatch<React.SetStateAction<TileColumns>>;

    shapeColumns: TileColumns;
    updateShapeColumns: React.Dispatch<React.SetStateAction<TileColumns>>;
}

const TileLayerPanelStoreContext = createContext<TileLayerPanelContextProps>(
    {} as TileLayerPanelContextProps
);
export const useTileLayerPanelStore = (): TileLayerPanelContextProps =>
    useContext(TileLayerPanelStoreContext);

export const TileLayerPanelProvider: React.FC = ({ children }) => {
    const [tables, setTables] = useState<TileTables>([]);
    const [colorColumns, setColorColumns] = useState<TileColumns>({});
    const [shapeColumns, setShapeColumns] = useState<TileColumns>({});

    const value = {
        tables,
        updateTables: setTables,

        colorColumns,
        updateColorColumns: setColorColumns,

        shapeColumns,
        updateShapeColumns: setShapeColumns,
    };

    return (
        <TileLayerPanelStoreContext.Provider value={value}>
            {children}
        </TileLayerPanelStoreContext.Provider>
    );
};
