export type Result<T, E> = Success<T> | Failure<E>;

export class Success<T> {
    constructor(readonly value: T) {}
    isSuccess(): this is Success<T> {
        return true;
    }
}

export class Failure<E> {
    constructor(readonly value: E) {}
    isSuccess() {
        return false;
    }
}
