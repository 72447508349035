import { MapPoint } from "domain/MapViewState";
import { RoadLookupService } from "application/port/mapPort";
import { fetchRoadLookup } from "service/api/roadLookupApi";

const lookupRoad = async (
    endpoint: string,
    tablename: string,
    roadid: string
): Promise<{
    error: boolean;
    mapPoint: MapPoint;
}> => {
    const init: RequestInit = {
        credentials: "include",
    };

    const res = await fetchRoadLookup(endpoint, init, tablename, roadid);

    const error = res.success;
    const mapPoint = {
        latitude: res.latitude,
        longitude: res.longitude,
        zoom: 15,
    };

    return {
        error,
        mapPoint,
    };
};

export const useRoadLookup = (): RoadLookupService => {
    return { lookupRoad };
};
