export function stringToIntHash(str, upperbound, lowerbound) {
    let result = 0;
    for (let i = 0; i < str.length; i++) {
        result = result + str.charCodeAt(i);
    }

    if (!lowerbound) lowerbound = 0;
    if (!upperbound) upperbound = 500;

    return (result % (upperbound - lowerbound)) + lowerbound;
}

export const getUnixTime = () => {
    const date = new Date();
    const unixtime = date.getTime();
    const datetime: number = Math.floor(unixtime / 1000); // 10桁にする
    return datetime;
};

export const dateToUnixTime = (date: Date | null) => {
    if (date == null) {
        return -1;
    } else {
        const unixtime = date.getTime();
        const datetime: number = Math.floor(unixtime / 1000);
        return datetime;
    }
};

export const unixTimeToDate = (unixtime: number) => {
    return new Date(unixtime * 1000);
};
