import { AvoidGeoJsonFeature, AvoidInformation } from "domain/AvoidInformation";
import { TileGeoJsonFeature } from "domain/TileGeoJsonFeature";
import { useIdTokenStorage } from "service/auth/authStorageAdapter";
import { useAvoid } from "service/avoid/avoidAdapter";
import {
    useSelectedAvoidListStorage,
    useAvoidModeStorage,
    useAvoidListStorage,
    useTicketAvoidStorage,
    useAvoidModalStorage,
} from "service/avoid/avoidStorageAdapter";
import { useNotificationStorage } from "service/notification/notificationStorageAdapter";

export const useAvoidService = () => {
    const avoidService = useAvoid();
    const avoidListStorage = useAvoidListStorage();
    const selectedAvoidListStorage = useSelectedAvoidListStorage();
    const avoidMode = useAvoidModeStorage();
    const { idToken } = useIdTokenStorage();
    const ticketAvoidStorage = useTicketAvoidStorage();
    const modalPositionStorage = useAvoidModalStorage();
    const notificationStorage = useNotificationStorage();

    const viewAvoid = async (
        bbox: string,
        organizationId: string,
        deleted: boolean
    ): Promise<void> => {
        const result = await avoidService.getAvoidsByBbox(
            organizationId,
            bbox,
            deleted
        );
        if (!result.isSuccess()) {
            notificationStorage.show(result.value.message, "error");
            return;
        }
        avoidListStorage.setAvoidList(result.value);
    };

    const addAvoid = async (avoid: AvoidInformation): Promise<void> => {
        const result = await avoidService.createAvoid(
            avoid,
            idToken,
            avoid.organizationId
        );
        if (!result.isSuccess()) {
            notificationStorage.show(result.value.message, "error");
            return;
        }
        avoidListStorage.setAvoidList([
            ...avoidListStorage.avoidList,
            {
                ...avoid,
                avoidId: result.value,
            },
        ]);
        selectedAvoidListStorage.setSelectedAvoidList([]);
        notificationStorage.show("Success!", "success");
    };

    const updateAvoid = async (avoid: AvoidInformation): Promise<void> => {
        if (!avoid.avoidId) {
            const err = new Error("Applicable avoid does not exists.");
            notificationStorage.show(err.message, "error");
            return;
        }

        const result = await avoidService.editAvoid(
            avoid.avoidId,
            avoid,
            idToken,
            avoid.organizationId
        );
        if (!result.isSuccess()) {
            notificationStorage.show(result.value.message, "error");
            return;
        }

        // update avoid list
        const avoidId = avoid.avoidId;
        const avoidList = avoidListStorage.avoidList;
        const updateIndex = avoidList.findIndex(
            (value) => value.avoidId == avoidId
        );
        if (updateIndex == -1) {
            const err = new Error("Applicable avoid does not exists.");
            notificationStorage.show(err.message, "error");
            return;
        }
        avoidListStorage.setAvoidList([
            ...avoidList.slice(0, updateIndex),
            avoid,
            ...avoidList.slice(updateIndex + 1),
        ]);
        selectedAvoidListStorage.setSelectedAvoidList([]);
        notificationStorage.show("Success!", "success");
    };

    const removeAvoid = async (avoidId: number): Promise<void> => {
        const avoidList = avoidListStorage.avoidList;
        const targetAvoid = avoidList.find(
            (avoid) => avoid.avoidId === avoidId
        );
        const removeIndex = avoidList.findIndex(
            (value) => value.avoidId == avoidId
        );
        if (removeIndex == -1) {
            const err = new Error("Applicable avoid does not exists.");
            notificationStorage.show(err.message, "error");
            return;
        }

        const result = await avoidService.removeAvoid(
            avoidId,
            idToken,
            targetAvoid ? targetAvoid.organizationId : ""
        );
        if (!result.isSuccess()) {
            notificationStorage.show(result.value.message, "error");
            return;
        }

        const newAvoidList = [...avoidList];
        newAvoidList.splice(removeIndex, 1);

        avoidListStorage.setAvoidList(newAvoidList);
        selectedAvoidListStorage.setSelectedAvoidList([]);
        ticketAvoidStorage.setTicketAvoid(null);
        notificationStorage.show("Success!", "success");
    };

    const selectAvoid = (
        geojson: TileGeoJsonFeature | AvoidGeoJsonFeature,
        x: number,
        y: number
    ): void | Error => {
        const linkId = geojson.properties.objectid;
        const avoidList = avoidListStorage.avoidList;

        if (avoidMode.mode == "add") {
            const ticketAvoid = ticketAvoidStorage.ticketAvoid;
            if (!ticketAvoid) {
                const err = new Error("Applicable avoid does not exists.");
                return err;
            }

            selectedAvoidListStorage.setSelectedAvoidList([ticketAvoid]);
            const selectedAvoids = [ticketAvoid];

            // set linkid
            selectedAvoids.forEach((avoid) => {
                avoid.linkId = linkId;
            });

            // set the midpoint of the road to coordinate
            const coordinates =
                geojson instanceof AvoidGeoJsonFeature
                    ? geojson.geometry.coordinates[0]
                    : geojson.geometry.coordinates;

            const sortedCoordinates = [...coordinates].sort((a, b) => {
                return a[0] - b[0];
            });
            let latitude: number;
            let longitude: number;
            if (sortedCoordinates.length % 2 == 0) {
                const midIndex = sortedCoordinates.length / 2;
                const coor1 = sortedCoordinates[midIndex - 1];
                const coor2 = sortedCoordinates[midIndex];
                latitude = (coor1[1] + coor2[1]) / 2;
                longitude = (coor1[0] + coor2[0]) / 2;
            } else {
                const midIndex = Math.round(sortedCoordinates.length / 2);
                latitude = sortedCoordinates[midIndex][1];
                longitude = sortedCoordinates[midIndex][0];
            }
            const newSelectedAvoids = selectedAvoids.map((avoid) => ({
                ...avoid,
                coordinate: {
                    latitude,
                    longitude,
                },
                geoJson: new AvoidGeoJsonFeature(
                    { type: "MultiLineString", coordinates: [coordinates] },
                    { objectid: linkId }
                ),
            }));
            selectedAvoidListStorage.setSelectedAvoidList(newSelectedAvoids);
            modalPositionStorage.setModalPositionXY(x, y);
        } else if (avoidMode.mode === "edit" || avoidMode.mode === "remove") {
            // チケットの情報
            const ticketAvoid = ticketAvoidStorage.ticketAvoid;
            if (!ticketAvoid) {
                const err = new Error("Applicable avoid does not exists.");
                return err;
            }
            // 対象のavoidを検索
            const targetAvoids = avoidList.filter(
                (avoid) =>
                    avoid.ticketId === ticketAvoid.ticketId &&
                    avoid.linkId === linkId
            );
            if (targetAvoids.length === 0) {
                const err = new Error("Applicable avoid does not exists.");
                console.log(err);
                return err;
            }
            // ticketの情報を反映させる
            const editedAvoids: AvoidInformation[] = targetAvoids.map(
                (targetAvoid) => ({
                    avoidId: targetAvoid.avoidId,
                    ticketId: targetAvoid.ticketId,
                    linkId: targetAvoid.linkId,
                    organizationId: targetAvoid.organizationId,
                    mapVersion: targetAvoid.mapVersion,
                    coordinate: targetAvoid.coordinate,
                    vehicleType: ticketAvoid.vehicleType,
                    date: ticketAvoid.date,
                    time: ticketAvoid.time,
                    geoJson: targetAvoid.geoJson,
                })
            );
            selectedAvoidListStorage.setSelectedAvoidList(editedAvoids);
            modalPositionStorage.setModalPositionXY(x, y);
        } else {
            const avoids = avoidList.filter((value) => value.linkId == linkId);
            if (avoids.length == 0) {
                const err = new Error("Applicable avoid does not exists.");
                return err;
            }

            selectedAvoidListStorage.setSelectedAvoidList([...avoids]);
            modalPositionStorage.setModalPositionXY(x, y);
        }
    };

    return {
        viewAvoid,
        addAvoid,
        updateAvoid,
        removeAvoid,
        selectAvoid,
    };
};
