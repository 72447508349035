import React, { Component, ComponentType } from "react";
// import { isEmptyBindingElement } from "typescript";

interface State {
    loading: boolean;
}

interface ComponentToProtectProps {
    endpoint: string;
    gcpProjectID: string;
}

export default function withAuth(
    ComponentToProtect: ComponentType<ComponentToProtectProps>,
    gcpProjectID: string,
    endpoint: string
) {
    return class Auth extends Component<Record<string, never>, State> {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        componentDidMount() {
            fetch(endpoint + "/auth/isAuth", {
                credentials: "include",
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    const success = responseJson.success;
                    const message = responseJson.message;
                    if (success) {
                        console.log(message);
                        this.setState({ loading: false });
                    } else {
                        const error = new Error(responseJson.error);
                        throw error;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading: false });
                });
        }

        render() {
            const { loading } = this.state;

            if (loading) {
                return null;
            }

            return (
                <ComponentToProtect
                    {...this.props}
                    endpoint={endpoint}
                    gcpProjectID={gcpProjectID}
                />
            );
        }
    };
}
