import { Geometry } from "./Geometry";

export class FeedbackData {
    type = "Feature";
    geometry: Geometry;
    properties: FeedbackProperties;

    constructor(geometry: Geometry, propertiess: FeedbackProperties) {
        this.geometry = geometry;
        this.properties = propertiess;
    }
}

export type FeedbackProperties = {
    event_name: string;
    event_timestamp: string;
    fb_type: string;
};

export type RawFeedbackData = {
    WKT: string;
    event_name: string;
    event_timestamp: string;
    fb_type: string;
};

export type BigQueryColumn = {
    name: string;
    type: string;
    mode: string;
};
