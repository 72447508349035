import { AvoidService } from "application/port/avoidPort";
import {
    RawAvoid,
    RequestPostAvoid,
    getAvoids,
    deleteAvoid,
    putAvoid,
    postAvoid,
    getRoads,
    fetchOrganizations,
} from "service/api/avoidApi";
import {
    AvoidCoordinate,
    AvoidDateTime,
    AvoidGeoJsonFeature,
    AvoidGeometry,
    AvoidInformation,
    VehicleType,
} from "domain/AvoidInformation";
import { Result, Success, Failure } from "domain/Result";
import wkx from "wkx";
import { formatDate, formatTime } from "utils/datetimeUtils";

const getAvoidsByRoadId = async (
    linkId: number,
    mapVersion = "" // 空文字で最新のmapを参照
): Promise<Result<number[], Error>> => {
    const params = { mapVersion };
    const result = await getRoads(linkId, params);

    return result;
};

const getAvoidsByBbox = async (
    organization: string,
    bbox: string,
    deleted: boolean
): Promise<Result<AvoidInformation[], Error>> => {
    const params = { organization, bbox, deleted };
    const result = await getAvoids(params);

    if (!result.isSuccess()) {
        return new Failure(result.value);
    }

    const avoidIds: RawAvoid[] = result.value;
    const avoids: AvoidInformation[] = avoidIds.map((rawAvoid) => {
        const coordinate: AvoidCoordinate = {
            latitude: rawAvoid.linkMidLat,
            longitude: rawAvoid.linkMidLon,
        };
        const date: AvoidDateTime = {
            start:
                rawAvoid.startDate === null
                    ? null
                    : new Date(rawAvoid.startDate),
            end: rawAvoid.endDate === null ? null : new Date(rawAvoid.endDate),
        };
        // TODO: time -> dateの関数を定義
        const time: AvoidDateTime = {
            start:
                rawAvoid.startTime === null
                    ? null
                    : new Date(`1999-01-01T${rawAvoid.startTime}+09:00`),
            end:
                rawAvoid.endTime === null
                    ? null
                    : new Date(`1999-01-01T${rawAvoid.endTime}+09:00`),
        };

        const wkbBuffer = Buffer.from(rawAvoid.linkShape, "hex");
        const geoJson: AvoidGeoJsonFeature = new AvoidGeoJsonFeature(
            wkx.Geometry.parse(wkbBuffer).toGeoJSON() as AvoidGeometry,
            { objectid: rawAvoid.linkId }
        );

        const avoid: AvoidInformation = {
            avoidId: rawAvoid.avoidId,
            ticketId: rawAvoid.ticketId,
            linkId: rawAvoid.linkId,
            organizationId: rawAvoid.organizationId,
            mapVersion: rawAvoid.mapVersion,
            coordinate,
            vehicleType: rawAvoid.vehicleType as VehicleType,
            date,
            time,
            geoJson,
        };

        return avoid;
    });

    return new Success(avoids);
};

const createAvoid = async (
    avoid: AvoidInformation,
    idToken: string,
    organization: string
): Promise<Result<number, Error>> => {
    if (!avoid.linkId) {
        const err = new Error("link id is invalid value");
        return new Failure(err);
    }

    const body: RequestPostAvoid = {
        organization: avoid.organizationId,
        ticketId: avoid.ticketId,
        linkId: avoid.linkId,
        mapVersion: null, // nullで最新のmapを参照
        vehicleType: avoid.vehicleType,
        startDate: formatDate(avoid.date.start),
        endDate: formatDate(avoid.date.end),
        startTime: formatTime(avoid.time.start),
        endTime: formatTime(avoid.time.end),
        heading: null,
    };

    const res = await postAvoid(avoid.linkId, body, idToken, organization);

    return res;
};

const removeAvoid = async (
    avoidId: number,
    idToken: string,
    organization: string
): Promise<Result<null, Error>> => {
    const res = await deleteAvoid(avoidId, idToken, organization);

    return res;
};

const editAvoid = async (
    avoidId: number,
    avoid: AvoidInformation,
    idToken: string,
    organization: string
): Promise<Result<null, Error>> => {
    if (!avoid.linkId) {
        const err = new Error("link id is invalid value");
        return new Failure(err);
    }

    const body: RequestPostAvoid = {
        organization: avoid.organizationId,
        ticketId: avoid.ticketId,
        linkId: avoid.linkId,
        mapVersion: null, // nullで最新のmapを参照
        vehicleType: avoid.vehicleType,
        startDate: formatDate(avoid.date.start),
        endDate: formatDate(avoid.date.end),
        startTime: formatTime(avoid.time.start),
        endTime: formatTime(avoid.time.end),
        heading: null,
    };
    const res = await putAvoid(avoidId, body, idToken, organization);

    return res;
};

const fetchOrganizationList = async (): Promise<Result<string[], Error>> => {
    return await fetchOrganizations();
};

export const useAvoid = (): AvoidService => {
    return {
        getAvoidsByRoadId,
        getAvoidsByBbox,
        createAvoid,
        removeAvoid,
        editAvoid,
        fetchOrganizationList,
    };
};
