export const get_sample_query = (startDate: string, endDate: string) => {
    return `
        SELECT
            ST_GeogPoint(longitude, latitude)  AS WKT,
            num_bikes_available,
            last_reported
        FROM
            \`bigquery-public-data.new_york.citibike_stations\`
        WHERE
            last_reported > '${startDate}' and last_reported < '${endDate}'
    `;
};

export const get_fb_query = (startDate: string, endDate: string) => {
    return `
    WITH
        fb AS (
            SELECT
                event_name,
                event_timestamp,
                ep
            FROM
                \`loogia-general-develop.analytics_179828461.events_20*\`,
                UNNEST(event_params) AS ep
            WHERE
                parse_date('%y%m%d', _table_suffix) between '${startDate}' AND '${endDate}'
                AND event_name="SEND_NAVIGATION_FEEDBACK"
                AND (ep.key = "LAT" OR ep.key = 'LON' OR ep.key = "FEEDBACK_TYPE")
        ),

        lon_table AS (
            SELECT
                event_timestamp,
                event_name,
                ep.value.double_value AS lon
            FROM
                fb
            WHERE
                ep.key="LON"
        ),

        lat_table AS (
            SELECT
                event_timestamp,
                ep.value.double_value AS lat
                FROM
                fb
            WHERE
                ep.key="LAT"
        ),

        fb_type_table AS (
            SELECT
                event_timestamp,
                ep.value.string_value AS fb_type
            FROM
                fb
            WHERE
                ep.key="FEEDBACK_TYPE"
        )
        
        SELECT
            event_name,
            event_timestamp,
            fb_type,
            ST_GeogPoint(lon, lat) AS WKT
        FROM
            lon_table
        JOIN
            lat_table
        USING
            (event_timestamp)
        JOIN
            fb_type_table
        USING
            (event_timestamp)
    `;
};
