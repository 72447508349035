import { fetchWrapper } from "./wrapper";

type GetTableResponse = string[];

type GetColumnsForTableResponse = {
    colorColumns: string[];
    shapeColumns: string[];
};

export const fetchTileTables = async (
    endpoint: string,
    init: RequestInit
): Promise<GetTableResponse> => {
    return fetchWrapper<GetTableResponse>(`${endpoint}/gettables`, init)
        .then((resJson) => {
            return resJson;
        })
        .catch((err) => {
            console.log(err);
            return [];
        });
};

export const fetchTileColumns = async (
    endpoint: string,
    init: RequestInit,
    tableName: string
): Promise<GetColumnsForTableResponse> => {
    return fetchWrapper<GetColumnsForTableResponse>(
        `${endpoint}/getcolumns/${tableName}`,
        init
    )
        .then((resJson) => {
            return {
                colorColumns: resJson.colorColumns,
                shapeColumns: resJson.shapeColumns,
            };
        })
        .catch((err) => {
            console.log(err);
            return {
                colorColumns: [],
                shapeColumns: [],
            };
        });
};
