import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

export const avoidPanelStyle = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            position: "absolute",
            top: 150,
            right: 100,
            width: 200,
            maxHeight: 198,
            backgroundColor: color.backgroud.panel,
            padding: 12,
            display: "block",
        },
        backdrop: {
            position: "absolute",
            top: 0,
            right: 0,
            width: 200,
            height: 198,
            padding: 12,
            zIndex: 10,
        },
        selectbox: {
            backgroundColor: "#fff",
            width: 180,
            padding: 5,
        },
        typography: {
            color: color.font.typography,
            display: "inline",
        },
        button: {
            cursor: "pointer",
            marginRight: 3,
            marginLeft: 20,
            backgroundColor: color.backgroud.button,
            textTransform: "none",
        },
        inputTicket: {
            display: "flex",
            alignItems: "center",
            width: 180,
            backgroundColor: "#fff",
            borderRadius: 5,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
    })
);
