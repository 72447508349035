import { RawFeedbackData } from "domain/FeedbackData";

import { RawFeedbackService } from "application/port/feedbackPort";
import { runGetFeedbackQuery } from "service/api/feedbackApi";

const getRawFeedback = async (
    projectID: string,
    startDate: string,
    endDate: string
): Promise<RawFeedbackData[]> => {
    const { rows } = await runGetFeedbackQuery(projectID, startDate, endDate);

    return rows;
};

export const useRawFeedbackGetter = (): RawFeedbackService => {
    return { getRawFeedback };
};
