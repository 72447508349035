import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

export const useFeedbackPanelStyle = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            position: "absolute",
            top: "30%",
            right: 5,
            backgroundColor: color.backgroud.panel,
            padding: 12,
            display: "block",
        },
        typography: {
            color: color.font.typography,
        },
        button: {
            float: "left",
            cursor: "pointer",
            marginRight: 3,
            backgroundColor: color.backgroud.button,
        },
    })
);
