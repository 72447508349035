import React, { useState, ReactElement, useEffect } from "react";
import { Route, BrowserRouter } from "react-router-dom";

import { MapProvider } from "service/store/mapStore";
import { AvoidProvider } from "service/store/avoidStore";

import withAuth from "../component/Auth/withAuth";
import RasterMap from "./Raster/RasterMap";
import { MapComponent } from "./Map/Map";
import Header from "../component/Header/Header";
import { appConfig } from "../config";
import {
    useBackendUrlStorageService,
    useIdTokenStorage,
} from "service/auth/authStorageAdapter";
import { useAuthService } from "application/auth/authUsecase";
import { NotificationProvider } from "service/store/notificationStore";
import { CustomizedSnackbar } from "component/CustomizedSnackbar/CustomizedSnackbar";

const GCP_PROJECT_ID = appConfig.GCP_PROJECT_ID;

const Router = (): ReactElement | null => {
    const { backendUrl } = useBackendUrlStorageService();
    const [loading, setLoading] = useState<boolean>(false);
    const { idToken } = useIdTokenStorage();
    const authUsecase = useAuthService();

    useEffect(() => {
        setLoading(true);
        authUsecase.login().then((err) => {
            setLoading(false);
            if (err) {
                alert("Error logging in please try again");
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading || idToken === "") return null;

    return (
        <BrowserRouter>
            <NotificationProvider>
                <MapProvider>
                    <AvoidProvider>
                        <Header endpoint={backendUrl} />
                        <Route
                            path="/"
                            exact
                            component={withAuth(
                                MapComponent,
                                GCP_PROJECT_ID || "",
                                backendUrl
                            )}
                        />
                    </AvoidProvider>
                </MapProvider>

                <CustomizedSnackbar />
            </NotificationProvider>
            <Route path="/raster" component={RasterMap} />
        </BrowserRouter>
    );
};

export default Router;
