import React, { createContext, useContext, useState } from "react";
import { AvoidInformation, AvoidMode } from "domain/AvoidInformation";

export interface AvoidContextProps {
    avoidList: AvoidInformation[];
    setAvoidList: React.Dispatch<React.SetStateAction<AvoidInformation[]>>;

    selectedAvoidList: AvoidInformation[];
    setSelectedAvoidList: React.Dispatch<
        React.SetStateAction<AvoidInformation[]>
    >;

    ticketAvoid: AvoidInformation | null;
    setTicketAvoid: React.Dispatch<
        React.SetStateAction<AvoidInformation | null>
    >;

    mode: AvoidMode;
    setMode: React.Dispatch<React.SetStateAction<AvoidMode>>;

    ticketId: number | null;
    setTicketId: React.Dispatch<React.SetStateAction<number | null>>;

    organizationId: string | null;
    setOrganizationId: React.Dispatch<React.SetStateAction<string | null>>;

    organizationIdList: string[];
    setOrganizationIdList: React.Dispatch<React.SetStateAction<string[]>>;

    modalPosition: { x: number; y: number };
    setModalPosition: React.Dispatch<
        React.SetStateAction<{ x: number; y: number }>
    >;
}

export const AvoidContext = createContext<AvoidContextProps>(
    {} as AvoidContextProps
);
export const useAvoidStore = (): AvoidContextProps => useContext(AvoidContext);

export const AvoidProvider: React.FC = ({ children }) => {
    const [avoidList, setAvoidList] = useState<AvoidInformation[]>([]);
    const [selectedAvoidList, setSelectedAvoidList] = useState<
        AvoidInformation[]
    >([]);
    const [ticketAvoid, setTicketAvoid] = useState<AvoidInformation | null>(
        null
    );
    const [mode, setMode] = useState<AvoidMode>("view");
    const [ticketId, setTicketId] = useState<number | null>(null);
    const [organizationId, setOrganizationId] = useState<string | null>(null);
    const [organizationIdList, setOrganizationIdList] = useState<string[]>([]);
    const [modalPosition, setModalPosition] = useState<{
        x: number;
        y: number;
    }>({ x: 0, y: 0 });

    const value: AvoidContextProps = {
        avoidList,
        setAvoidList,

        selectedAvoidList,
        setSelectedAvoidList,

        ticketAvoid,
        setTicketAvoid,

        mode,
        setMode,

        ticketId,
        setTicketId,

        organizationId,
        setOrganizationId,

        organizationIdList,
        setOrganizationIdList,

        modalPosition,
        setModalPosition,
    };

    return (
        <AvoidContext.Provider value={value}>{children}</AvoidContext.Provider>
    );
};
