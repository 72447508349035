export const fetchWrapper = async <T>(
    request: RequestInfo,
    init?: RequestInit
): Promise<T> => {
    const response = await fetch(request, init);

    if (!response.ok) {
        throw new Error(String(response.status) + ":" + response.statusText);
    }

    return response.json().catch((error) => {
        console.log(error);
    });
};
