import Amplify, { Auth } from "aws-amplify";
import { Failure } from "domain/Result";
import { useAuth } from "service/auth/authAdapter";
import {
    useBackendUrlStorageService,
    useIdTokenStorage,
    useOrganizationStorage,
    useReadableOrgIdListStorage,
} from "service/auth/authStorageAdapter";
import { appConfig } from "../../config";

Amplify.configure({
    Auth: {
        region: appConfig.COGNITO_USER_POOL_REGION,
        userPoolId: appConfig.COGNITO_USER_POOL_ID,
        userPoolWebClientId: appConfig.COGNITO_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: appConfig.COGNITO_OAUTH_DOMAIN,
            scope: ["email", "openid", "profile"],
            redirectSignIn: appConfig.COGNITO_OAUTH_REDIRECT_SIGNIN_URL,
            redirectSignOut: appConfig.COGNITO_OAUTH_REDIRECT_SIGNOUT_URL,
            responseType: "code",
        },
    },
});

export const useAuthService = () => {
    const authService = useAuth();
    const { setIdToken } = useIdTokenStorage();
    const { setOrganization } = useOrganizationStorage();
    const { setReadableOrgIdList } = useReadableOrgIdListStorage();
    const { backendUrl, setBackendUrl } = useBackendUrlStorageService();

    const login = async (): Promise<void | Error> => {
        await Auth.currentSession()
            .then(async (data) => {
                const idToken = data.getIdToken().getJwtToken();
                const payload = data.getIdToken().payload;
                const organization = payload["custom:organization"];
                const backendEndpoint = payload["custom:backend_url"];
                const readableOrgIdList = payload[
                    "custom:readable_org_ids"
                ].split("|");
                setBackendUrl(backendEndpoint);
                setReadableOrgIdList(readableOrgIdList);
                setOrganization(organization);
                setIdToken(idToken);

                const result = await authService.login(backendEndpoint);
                if (!result.isSuccess()) {
                    return result.value;
                }
            })
            .catch((err) => {
                console.log(err);
                Auth.federatedSignIn();
            });
    };

    const logout = async (): Promise<void | Error> => {
        Auth.signOut()
            .then(async () => {
                const result = await authService.logout(backendUrl);
                if (!result.isSuccess()) {
                    return result.value;
                }
            })
            .catch((err) => {
                return new Failure(err);
            });
    };

    return {
        login,
        logout,
    };
};
