import { initial, clone, last, toPairs } from "lodash-es";

export function generateBins(smallCutoff, largeCutoff, numBins) {
    const step = (largeCutoff - 1 - smallCutoff) / numBins;
    const bins = [...Array(numBins - 1).keys()].map(
        (i) => smallCutoff + (i + 1) * step
    );
    return bins;
}

export function numberToBin(bins, number) {
    const siz = bins.length + 1;
    for (let i = 0; i < siz - 1; i++) {
        if (number < bins[i]) {
            return i;
        }
    }
    return siz - 1;
}

export function sumBinCounts(binCountArray) {
    // precondition: all elements of binCountArray are {k:v} where k, v are integers
    // and all elements have the same set of k. non-empty

    //get all elements but the last element
    const allButLast = initial(binCountArray);
    const lastElement = clone(last(binCountArray));
    return allButLast.reduce((acc, cur) => {
        toPairs(cur).forEach((kvPair) => (acc[kvPair[0]] += kvPair[1]));
        //cur.keys().forEach((k) => acc[k] += cur[k])
        return acc;
    }, lastElement);
}

/**
 *  Given {1:3, 2:6, 3:7} return [{x:1,y:3}, {x:2,y:6},{x:3,y:7}]
 * @param binCounts
 * @returns {any}
 */
export function binCountsToXYData(binCounts) {
    return toPairs(binCounts).map((kvPair) => {
        return { x: Number(kvPair[0]), y: kvPair[1] };
    });
}
