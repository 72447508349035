import React from "react";

import { tooltipStyle } from "./style";

import { HoveredObject, TileCoordinate } from "domain/LayerInformation";
import { SearchPinState } from "application/path/SearchPinState";

interface Props {
    tileCoordinate: TileCoordinate | null;
    searchPinState: SearchPinState;
    hoveredObject: HoveredObject | null;
}

const LayerTooltip = (props: Props) => {
    const coords = props.tileCoordinate ?? { x: 0, y: 0 };
    const classes = tooltipStyle({
        x: coords.x,
        y: coords.y,
    });

    const renderTooltip = () => {
        const isHovering = Boolean(props.hoveredObject);
        if (isHovering) {
            const properties = props.hoveredObject?.properties ?? {};
            let layerInfo = {};
            if (Array.isArray(properties)) {
                for (let i = 0; i < properties.length; i++) {
                    layerInfo = { ...layerInfo, ...properties[i] };
                }
            } else {
                layerInfo = properties;
            }
            const propKeys = Object.keys(layerInfo);

            return (
                <div className={classes.root}>
                    {propKeys.map((k) => {
                        return (
                            <div key={k}>
                                {k}:{layerInfo[k]}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    };

    if (props.searchPinState.using) {
        const msg = props.searchPinState.isStart
            ? "set depature point"
            : "set arrival point";
        return <div className={classes.root}>{msg}</div>;
    } else {
        return renderTooltip();
    }
};

export default LayerTooltip;
