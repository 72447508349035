import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

export const avoidModalStyle = makeStyles((theme: Theme) =>
    createStyles({
        panel: (coord: { x: number; y: number }) => ({
            position: "absolute",
            top: coord.y - 359 < 0 ? 0 : coord.y - 359,
            left: coord.x - 359 < 0 ? 0 : coord.x - 359,
            width: 325,
            height: 325,
            backgroundColor: "rgba(176, 170, 170, 0.95)",
            padding: 12,
            display: "block",
            zIndex: 10,
        }),
        backdrop: {
            position: "absolute",
            top: 0,
            right: 0,
            width: 325,
            height: 325,
            padding: 12,
            zIndex: 10,
        },
        typography: {
            color: color.font.typography,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 25,
            marginBottom: 10,
        },
        closeIcon: {
            position: "absolute",
            top: -5,
            left: 310,
        },
        coordInput: {
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 25,
            marginBottom: 10,
        },
        input: {
            backgroundColor: "#fff",
            width: 110,
        },
        selectbox: {
            backgroundColor: "#fff",
            width: 180,
            padding: 5,
            marginBottom: 10,
        },
        dateTimePicker: {
            width: 137,
            backgroundColor: "#fff",
            marginBottom: 10,
        },
        dateTimeDash: {
            marginRight: 10,
            marginLeft: 10,
        },
        button: {
            cursor: "pointer",
            backgroundColor: color.backgroud.button,
            textTransform: "none",
        },
        blueButton: {
            cursor: "pointer",
            backgroundColor: "#1C79CF",
            color: "#fff",
            textTransform: "none",
        },
        redButton: {
            cursor: "pointer",
            backgroundColor: "#E92929",
            color: "#fff",
            textTransform: "none",
        },
    })
);
