import {
    useTileTableStorage,
    useTileColorColumnsStorage,
    useTileShapeColumnsStorage,
} from "service/tile/tileLayerPanelStorageAdapter";
import {
    useGetTileTables,
    useGetTileColumns,
} from "service/tile/tileInformationAdapter";

export const useUpdateTileTables = () => {
    const tableStorage = useTileTableStorage();
    const colorColumnStorage = useTileColorColumnsStorage();
    const shapeColumnStorage = useTileShapeColumnsStorage();
    const getTileTables = useGetTileTables();

    const updateTables = async (endpoint: string): Promise<void> => {
        const res = await getTileTables.getTileTables(endpoint);

        const colorColumns = colorColumnStorage.colorColumns;
        const shapeColumns = shapeColumnStorage.shapeColumns;

        res.forEach((tableName: string) => {
            Object.assign(colorColumns, { [tableName]: [] });
            Object.assign(shapeColumns, { [tableName]: [] });
        });

        tableStorage.updateTables(res);
        colorColumnStorage.updateColorColumns(colorColumns);
        shapeColumnStorage.updateShapeColumns(shapeColumns);
    };

    return { updateTables };
};

export const useUpdateTileColumns = () => {
    const colorColumnStorage = useTileColorColumnsStorage();
    const shapeColumnStorage = useTileShapeColumnsStorage();
    const getTileColumns = useGetTileColumns();

    const updateColumns = async (
        endpoint: string,
        tableName: string
    ): Promise<void> => {
        const res = await getTileColumns.getTileColumns(endpoint, tableName);

        colorColumnStorage.updateColorColumns({
            ...colorColumnStorage.colorColumns,
            ...res.colorColumns,
        });
        shapeColumnStorage.updateShapeColumns({
            ...shapeColumnStorage.shapeColumns,
            ...res.shapeColumns,
        });
    };

    return { updateColumns };
};
