import React from "react";

import { useFeedbackDataStorage } from "service/map/mapStorageAdapter";

const FeedbackColorTable = () => {
    const { colorMap } = useFeedbackDataStorage();

    const getRgb = (color: number[]) => {
        return (
            "rgb(" +
            String(color[0]) +
            "," +
            String(color[1]) +
            "," +
            String(color[2]) +
            ")"
        );
    };

    const renderColorTable = () => {
        const elements: any = [];
        colorMap.forEach((value, key) => {
            elements.push(
                <div
                    key={key}
                    style={{
                        background: getRgb(value),
                        clear: "both",
                        margin: "5px",
                    }}
                >
                    {key}
                </div>
            );
        });

        return elements;
    };

    return <div>{renderColorTable()}</div>;
};

export default FeedbackColorTable;
