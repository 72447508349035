import { useTileDifinitionStorage } from "service/map/mapStorageAdapter";

export const useUpdateTileDefinitions = () => {
    const tileDefinitionsStorage = useTileDifinitionStorage();
    const { tileDefinitions, setTileDefinitions } = tileDefinitionsStorage;

    const updateTileDefinitions = (
        value: string | boolean | number,
        tableName: string,
        key: string
    ): void => {
        let addDefinition = {};
        if (tableName in tileDefinitions) {
            addDefinition = Object.assign(tileDefinitions[tableName], {
                [key]: value,
            });
        } else {
            addDefinition = { [key]: value };
        }

        const newTileDefinitions = {
            ...tileDefinitions,
            ...{ [tableName]: addDefinition },
        };

        setTileDefinitions(newTileDefinitions);
    };

    return { updateTileDefinitions };
};

export const useDeleteTileDefinitions = () => {
    const tileDefinitionsStorage = useTileDifinitionStorage();
    const { tileDefinitions, setTileDefinitions } = tileDefinitionsStorage;

    const deleteTileDefinition = (selectTableName: string) => {
        const newTileDefinitions = { ...tileDefinitions };
        delete newTileDefinitions[selectTableName];
        setTileDefinitions(newTileDefinitions);
    };

    return { deleteTileDefinition };
};
