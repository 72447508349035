import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";

import { useFeedback } from "application/feedback/feedbackUsecase";
import { useFeedbackDataStorage } from "service/map/mapStorageAdapter";

import FeedbackButton from "component/FeedbackPanel/FeedbackButton";
import FeedbackColorTable from "component/FeedbackPanel/FeedbackColorTable";
import { useFeedbackPanelStyle } from "component/FeedbackPanel/style";

// const TIME_TEMPLATE = "YYYY-MM-DD HH:mm:ss";
const TIME_TEMPLATE = "YYYY-MM-DD";

interface FetchFeedbackError {
    code: number;
    message: string;
}
interface Props {
    projectID: string;
}

const FeedbackPanel = (props: Props) => {
    const classes = useFeedbackPanelStyle();

    const feedbackUsecase = useFeedback();
    const { colorMap } = useFeedbackDataStorage();

    const [isShow, setIsShow] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState<FetchFeedbackError>({
        code: 200,
        message: "",
    });

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState();

    const [startDateStr, setStartDateStr] = useState("");
    const [endDateStr, setEndDateStr] = useState("");

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const fetchFeadBackErrorHandler = (code: number, message: string) => {
        setModalMessage({ code: code, message: message });
        setModalIsOpen(true);
    };

    const deleteFbLayer = () => {
        if ([...colorMap].length === 0) {
            fetchFeadBackErrorHandler(-1, "feedback layer is not rendered");
        }
        feedbackUsecase.deleteFeedbackData();
    };

    return (
        <div className={classes.panel}>
            {isShow && (
                <DateRangePicker
                    startDate={startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={endDate}
                    endDateId="your_unique_end_date_id"
                    onDatesChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);

                        if (endDate != null) {
                            setStartDateStr(startDate.format(TIME_TEMPLATE));
                            setEndDateStr(endDate.format(TIME_TEMPLATE));
                        }
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                    }}
                    isOutsideRange={(day) =>
                        !isInclusivelyBeforeDay(day, moment())
                    }
                />
            )}

            {isShow && <FeedbackColorTable />}

            <FeedbackButton
                projectID={props.projectID}
                startDate={startDateStr}
                endDate={endDateStr}
                errorHandler={fetchFeadBackErrorHandler}
            />

            {isShow && (
                <Button
                    className={classes.button}
                    onClick={() => deleteFbLayer()}
                >
                    delete layer
                </Button>
            )}

            <Button
                className={classes.button}
                onClick={() => {
                    setIsShow(!isShow);
                }}
            >
                {isShow ? "hide" : "show"}
            </Button>

            <Modal
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                    },
                }}
                isOpen={modalIsOpen}
            >
                <h2>ERROR</h2>
                <div>
                    code: {modalMessage.code}, message: {modalMessage.message}
                </div>
                <button onClick={closeModal}>close</button>
            </Modal>
        </div>
    );
};

export default FeedbackPanel;
