export const formatDate = (date: Date | null): string | null => {
    if (date === null) {
        return null;
    }
    const year = date.getFullYear();
    const month = ("00" + (date.getMonth() + 1)).slice(-2);
    const day = ("00" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
};

export const formatTime = (time: Date | null): string | null => {
    if (time === null) {
        return null;
    }
    const hours = ("00" + time.getHours()).slice(-2);
    const minutes = ("00" + time.getMinutes()).slice(-2);
    const seconds = ("00" + time.getSeconds()).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
};
