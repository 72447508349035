import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

export const headerStyle = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            position: "relative",
            zIndex: 1,
            backgroundColor: color.backgroud.header,
            display: "inline-block",

            "& Button": {
                display: "inline-block",
            },
        },

        typography: {
            flexGrow: 1,
            color: color.font.typography,
            textDecorationColor: "dc0a0a",
        },

        select: {
            minWidth: 120,
        },
    })
);
