import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

type Coordinate = {
    x: number;
    y: number;
};

export const tooltipStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: Coordinate) => ({
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            padding: 5,
            left: props.x,
            top: props.y,
            background: color.backgroud.box,
            transition: "left 0.2s 0.2s, top 0.2s 0.2s",
        }),
    })
);
