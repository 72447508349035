import React from "react";

import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { PathInfo, PointParam } from "domain/PathInfo";
import { usePathInfo } from "application/path/pathInfoUsecase";
import { usePathInfoStorage } from "service/map/mapStorageAdapter";

import RouteSearchTextField from "./RouteSearchTextField";
import { dateToUnixTime, unixTimeToDate } from "../../utils/otherUtils";

import { useSearchPanelStyle } from "./style";

interface Props {
    index: number;
    searchPath: (index: number) => Promise<void>;
    deletePathLayer: (index: number) => void;
}

const RouteSearchBox = (props: Props) => {
    const { pathInfos } = usePathInfoStorage();
    const pathInfoUsecase = usePathInfo();

    const pathInfo = pathInfos[props.index];

    const getRgb = (color: number[]) => {
        return `rgb(
            ${String(color[0])}, ${String(color[1])}, ${String(color[2])}
        )`;
    };

    const classes = useSearchPanelStyle({
        color: getRgb(pathInfo.routeColor),
    });

    const updatePathPoint = (
        param: PointParam,
        point: string,
        index: number
    ) => {
        const oldPathInfo = pathInfo;
        const newPathInfo = new PathInfo(
            oldPathInfo.startPoint,
            oldPathInfo.stopPoint,
            oldPathInfo.datetime,
            oldPathInfo.routeColor
        );
        newPathInfo.pathData = oldPathInfo.pathData;
        newPathInfo.datetime = oldPathInfo.datetime;
        if (point === "start") {
            newPathInfo.startPoint = param;
        } else if (point === "goal") {
            newPathInfo.stopPoint = param;
        }

        pathInfoUsecase.setPathInfos(index, newPathInfo);
    };

    return (
        <div className={classes.box}>
            <div className={classes.colorBar}></div>

            <RouteSearchTextField
                index={props.index}
                lat={pathInfo.startPoint.latitude}
                lon={pathInfo.startPoint.longitude}
                preferredSide={pathInfo.startPoint.preferredSide}
                title="出発地"
                point="start"
                updatePathPoint={updatePathPoint}
            />
            <RouteSearchTextField
                index={props.index}
                lat={pathInfo.stopPoint.latitude}
                lon={pathInfo.stopPoint.longitude}
                preferredSide={pathInfo.stopPoint.preferredSide}
                title="目的地"
                point="goal"
                updatePathPoint={updatePathPoint}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={unixTimeToDate(pathInfo.datetime)}
                    onChange={(date) => {
                        pathInfo.datetime = dateToUnixTime(date);
                        pathInfoUsecase.setPathInfos(props.index, pathInfo);
                    }}
                    KeyboardButtonProps={{ "aria-label": "change time" }}
                />
            </MuiPickersUtilsProvider>
            <div>所要時間： {pathInfo.pathTime}</div>

            <div className={classes.searchBsutton}>
                <Button
                    className={classes.button}
                    onClick={() => props.searchPath(props.index)}
                >
                    search
                </Button>
                <Button
                    className={classes.button}
                    onClick={() => props.deletePathLayer(props.index)}
                >
                    delete
                </Button>
            </div>
        </div>
    );
};

export default RouteSearchBox;
