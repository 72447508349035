import React, { useState, useEffect } from "react";

import Modal from "react-modal";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useDeleteTileDefinitions } from "application/tile/tileDefinitionUsecase";
import {
    useUpdateTileTables,
    useUpdateTileColumns,
} from "application/tile/tileInformationUsecase";
import {
    useTileTableStorage,
    useTileColorColumnsStorage,
    useTileShapeColumnsStorage,
} from "service/tile/tileLayerPanelStorageAdapter";

import TileLayerSetBox from "./TileLayerSetBox";
import { tileLayerPanelStyle } from "./style";

Modal.setAppElement("#root");

interface Props {
    endpoint: string;
}

const TileLayerPanel = (props: Props) => {
    const classes = tileLayerPanelStyle();

    const [modalState, setModalState] = useState(false);
    const [selectTableNames, setSelectTableNames] = useState<Array<string>>([]);
    const [isLayerboxDetail, setIsLayerboxDetail] = useState<Array<boolean>>(
        []
    );

    const { tables } = useTileTableStorage();
    const { colorColumns } = useTileColorColumnsStorage();
    const { shapeColumns } = useTileShapeColumnsStorage();

    const { deleteTileDefinition } = useDeleteTileDefinitions();
    const { updateTables } = useUpdateTileTables();
    const { updateColumns } = useUpdateTileColumns();

    useEffect(
        () => {
            updateTables(props.endpoint);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const setTable = (tableName: string) => {
        if (selectTableNames.indexOf(tableName) === -1) {
            setSelectTableNames([...selectTableNames, tableName]);
            setIsLayerboxDetail([...isLayerboxDetail, false]);
            updateColumns(props.endpoint, tableName);
        } else {
            // eslint-disable-next-line no-undef
            alert("すでにレンダリングされてます");
        }
    };

    const openLayerbox = (tableIndex: number) => {
        setIsLayerboxDetail([
            ...isLayerboxDetail.slice(0, tableIndex),
            !isLayerboxDetail[tableIndex],
            ...isLayerboxDetail.slice(tableIndex + 1),
        ]);
    };

    const renderLayerBox = (tableName: string, tableIndex: number) => {
        if (!isLayerboxDetail[tableIndex]) {
            return;
        } else {
            return (
                <TileLayerSetBox
                    tableName={tableName}
                    columnsByTable={colorColumns}
                    shapeColumnsByTable={shapeColumns}
                />
            );
        }
    };

    const deleteLayer = (tableIndex: number) => {
        deleteTileDefinition(selectTableNames[tableIndex]);

        const newSelectTableNames = [...selectTableNames];
        newSelectTableNames.splice(tableIndex, 1);
        setSelectTableNames(newSelectTableNames);

        const newIsLayerboxDetail = [...isLayerboxDetail];
        newIsLayerboxDetail.splice(tableIndex, 1);
        setIsLayerboxDetail(newIsLayerboxDetail);
    };

    return (
        <div className={classes.panel}>
            <Typography className={classes.typography} variant="h5">
                Layers
            </Typography>

            {selectTableNames.map((tableName, tableIndex) => {
                return (
                    <div className={classes.box} key={tableIndex}>
                        <Typography className={classes.typography} variant="h6">
                            {tableName}
                        </Typography>

                        <Button
                            className={classes.button}
                            onClick={() => openLayerbox(tableIndex)}
                        >
                            detail
                        </Button>
                        <Button
                            className={classes.button}
                            onClick={() => deleteLayer(tableIndex)}
                        >
                            delete
                        </Button>

                        {renderLayerBox(tableName, tableIndex)}
                    </div>
                );
            })}

            <br />
            <Button
                className={classes.button}
                variant="outlined"
                onClick={() => setModalState(true)}
            >
                + add data
            </Button>

            <Modal
                className={classes.modal}
                isOpen={modalState}
                onRequestClose={() => setModalState(false)}
                contentLabel="Example Modal"
            >
                {tables.map((value, index) => (
                    <Button
                        className={classes.modalButton}
                        key={index}
                        variant="outlined"
                        onClick={() => {
                            setTable(value);
                            setModalState(false);
                        }}
                    >
                        {value}
                    </Button>
                ))}
                <Button
                    className={classes.modalCloseButton}
                    variant="contained"
                    color="primary"
                    onClick={() => setModalState(false)}
                >
                    閉じる
                </Button>
            </Modal>
        </div>
    );
};

export default TileLayerPanel;
