import { VectorTile } from "@mapbox/vector-tile";
import Protobuf from "pbf";

import { TileGeoJsonFeature } from "domain/TileGeoJsonFeature";
import { TileGeoJsonFeatureService } from "application/port/tilePort";
import { fetchMvt } from "service/api/vectorTileApi";

export interface getTileDataParam {
    table: string;
    column: string;
    shapeColumn: string;
    isCondition: string;
    condColumn: string;
    inequalitySign: string;
    condValue: string;
}

const paramToRecords = (params: getTileDataParam): Record<string, string> => {
    const records: Record<string, string> = {
        table: params.table,
        column: params.column,
        shapeColumn: params.shapeColumn,
        isCondition: params.isCondition,
        condColumn: params.condColumn,
        inequalitySign: params.inequalitySign,
        condValue: params.condValue,
    };

    return records;
};

const getTileData = async (
    endpoint: string,
    params: getTileDataParam,
    x: number,
    y: number,
    z: number
): Promise<TileGeoJsonFeature[]> => {
    const qs = new URLSearchParams(paramToRecords(params));
    const mapSource = `/mvt/${z}-${x}-${y}?${qs}`;

    const init: RequestInit = {
        credentials: "include",
    };

    const buffer = await fetchMvt(endpoint + mapSource, init);

    const tile = new VectorTile(new Protobuf(buffer));

    const features: TileGeoJsonFeature[] = [];
    for (const layerName in tile.layers) {
        const vectorTileLayer = tile.layers[layerName];
        for (let i = 0; i < vectorTileLayer.length; i++) {
            const vectorTileFeature = vectorTileLayer.feature(i);
            const feature = vectorTileFeature.toGeoJSON(x, y, z);
            features.push(feature);
        }
    }

    return features;
};

export const useGetTileData = (): TileGeoJsonFeatureService => {
    return {
        getTileData(
            endpoint: string,
            params: getTileDataParam,
            x: number,
            y: number,
            z: number
        ) {
            return getTileData(endpoint, params, x, y, z);
        },
    };
};
