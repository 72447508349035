import { SearchPathService } from "application/port/pathPort";

import { fetchSearchPath } from "service/api/routeApi";

export interface SearchPathParam {
    s_latitude: number;
    s_longitude: number;
    s_preferredSide: string;
    g_latitude: number;
    g_longitude: number;
    g_preferredSide: string;
    datetime: number;
}

export interface SearchPathResponse {
    pathData: number[][];
    pathTime: number;
}

const searchPath = async (
    endpoint: string,
    params: SearchPathParam
): Promise<SearchPathResponse> => {
    const init: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(params),
    };
    const res = await fetchSearchPath(endpoint, init);

    return res;
};

export const usePathSearcher = (): SearchPathService => {
    return { searchPath };
};
