import { MapViewState, MapPoint } from "domain/MapViewState";
import { useViewStateStorage } from "service/map/mapStorageAdapter";
import { useRoadLookup } from "service/map/roadLookupAdapter";

export interface ViewStateInterface {
    latitude: number;
    longitude: number;
    zoom: number;
    bearing: number;
    pitch: number;
    transitionDuration: number;
}

export const useViewState = () => {
    const viewStateStorage = useViewStateStorage();
    const roadSearcher = useRoadLookup();

    const updateViewState = (newViewState: MapViewState): void => {
        viewStateStorage.updateViewState(newViewState);
    };

    const updatePoint = (newPoint: MapPoint): void => {
        const newViewState = Object.assign(
            {},
            viewStateStorage.viewState,
            newPoint
        );
        viewStateStorage.updateViewState(newViewState);
    };

    const lookupRoad = async (
        endpoint: string,
        tablename: string,
        roadid: string
    ): Promise<void> => {
        const { error, mapPoint } = await roadSearcher.lookupRoad(
            endpoint,
            tablename,
            roadid
        );
        if (!error) {
            throw new Error("road lookup error");
        }
        updatePoint(mapPoint);
    };

    return {
        updateViewState,
        updatePoint,
        lookupRoad,
    };
};
