import { IconLayer } from "@deck.gl/layers";
import {
    useTicketAvoidStorage,
    useAvoidModeStorage,
} from "service/avoid/avoidStorageAdapter";

// TODO: prepare avoid marker
const ICON_ATLAS =
    "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png";
const ICON_MAPPING = {
    marker: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: true },
};

export const useAvoidMarker = () => {
    const ticketAvoidStorage = useTicketAvoidStorage();
    const avoidModeStorage = useAvoidModeStorage();

    const renderAvoidMarker = () => {
        const { ticketAvoid } = ticketAvoidStorage;
        const { mode } = avoidModeStorage;

        const coordinates =
            mode !== "view" && ticketAvoid
                ? [
                      {
                          coordinates: [
                              Number(ticketAvoid.coordinate.longitude),
                              Number(ticketAvoid.coordinate.latitude),
                          ],
                      },
                  ]
                : [];

        const layer = new IconLayer({
            id: "avoid-marker",
            data: coordinates,
            pickable: true,
            iconAtlas: ICON_ATLAS,
            iconMapping: ICON_MAPPING,
            getIcon: (d) => "marker",
            sizeScale: 15,
            getPosition: (d) => d.coordinates,
            getSize: (d) => 5,
            getColor: (d) => [Math.sqrt(d.exits), 140, 0],
        });

        return layer;
    };

    return { renderAvoidMarker };
};
