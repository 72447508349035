import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { color } from "config";

type colorBarType = {
    color: string;
};

export const useSearchPanelStyle = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            position: "absolute",
            top: "50%",
            right: 5,
            maxHeight: "45%",
            backgroundColor: color.backgroud.panel,
            padding: 12,
            display: "block",
            overflow: "scroll",
        },
        box: {
            borderRadius: "0.6em",
            backgroundColor: color.backgroud.box,
            margin: 5,
            padding: 5,
        },
        searchBsutton: {
            marginTop: 15,
            marginLeft: 15,
        },
        colorBar: (props: colorBarType) => ({
            background: props.color,
            height: 10,
            width: 200,
        }),
        typography: {
            color: color.font.typography,
        },
        button: {
            cursor: "pointer",
            marginRight: 3,
            backgroundColor: color.backgroud.button,
        },
    })
);
